@import '../../scss/variables.scss';
.vipContainer {
  background: url('../../assets/vip-top.webp'),
    url('../../assets/vip-bottom.webp');
  background-repeat: no-repeat, no-repeat;
  background-size: 150px auto, 120px auto;
  background-position: top 0px left 10px, bottom 0 right 0;
  padding: $space-lg $mobilePadding;
  background-color: $headerBgColor;
  margin-top: $space-lg;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: $space-md;
  h3 {
    color: $textColorLight;
    font-family: $font;
    font-size: $font-size-large;
    font-style: normal;
    font-weight: $font-weight-extra-bold;
    line-height: normal;
    text-transform: uppercase;
    text-align: center;
  }
  p {
    color: $textColorLight;
    font-family: $font;
    font-size: $font-size-small;
    font-style: normal;
    font-weight: $font-weight-normal;
    line-height: normal;
    text-align: center;
  }
  h4 {
    color: $textColorLight;
    font-family: $font;
    font-size: $font-size-base;
    font-style: normal;
    font-weight: $font-weight-semi-bold;
    line-height: normal;
    text-align: center;
  }
  @include smallBreakpoint {
  }
  @include mediumBreakpoint {
    background-size: 200px auto, 160px auto;
  }
  @include largeBreakpoint {
    width: 100%;
    background-size: 407px auto, 347px auto;
    h3 {
      font-size: $font-size-h1;
    }
    p {
      font-size: $font-size-base;
    }
    h4 {
      font-size: $font-size-large;
    }
  }
}
