@import '../../scss/variables.scss';

.socialLink {
  background-color: $primaryColor;
  color: $textColorLight;
  border-radius: 50%;
  width: 41px;
  height: 41px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  cursor: pointer;
  border: none;
  img {
    color: $primaryColor;
  }
}
