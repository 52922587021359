@import '../../scss/variables.scss';

.imageCropper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: $space-md;
  .imageCropperContainer {
    position: relative;
    width: 800px;
    min-width: 100%;
    max-width: 100%;
    min-height: 350px;
    height: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
  .inputContainer {
    margin-top: 10px;
    width: 100%;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    justify-content: space-between;
    gap: $space-md;
    .sliderContainer {
      .slide {
        accent-color: $primaryColor;
        border: none;
      }
    }
  }
}
