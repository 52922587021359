@import '../../../scss/variables.scss';

.tickets {
  .ticketsContainer {
    margin-top: $space-lg;
    .titleContainer {
      h2 {
      }
      .cardsContainer {
        display: flex;
        flex-direction: column;
        gap: 40px;
        width: 100%;
        margin-top: $space-md;
      }
    }
  }
}
