@import '../../scss/variables.scss';

.scheduleDemo {
  padding: 44px 0 80px;
  .scheduleDemoContainer {
    .section1 {
      padding-left: $mobilePadding;
      padding-right: $mobilePadding;
      .reasons {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 31px;
        h2 {
          color: $textColorDark;
          text-align: center;
          font-family: $font;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
        ul {
          display: flex;
          flex-direction: column;
          gap: 25px;
          list-style: none;
          max-width: 235px;
          li {
            color: $textColorDark;
            display: grid;
            grid-template-columns: 35px 1fr;
            gap: 10px;
            .iconContainer {
              height: 34px;
              width: 34px;
              background-color: #f6fafe;
              border-radius: 17px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            span {
              color: $textColorSemiDark;
              font-family: $font;
              font-size: 12px;
              font-style: normal;
              font-weight: 700;
              line-height: 182.602%;
              display: flex;
              align-items: center;
              justify-content: flex-start;
            }
          }
        }
      }
      .formContainer {
        margin-top: 45px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        form {
          border-radius: 20px;
          padding: 31px 40px 40px 28px;
          background-color: $headerBgColor;
          max-width: 430px;
          color: $textColorLight;
          display: flex;
          width: 100%;
          flex-direction: column;
          gap: 10px;
          h3 {
            font-family: $font;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
          .nameContainer {
            display: flex;
            gap: 6px;
          }
          .phoneContainer {
            display: flex;
            width: calc(50% - 6px);
          }
          label {
            font-family: $interFont;
            font-size: 11px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            textarea {
              margin-top: 5px;
              width: 100%;
              height: 101px;
              resize: none;
              border: none;
              border-radius: 3px;
              padding: 8px;
              font-family: $font;
              color: #8d8d8d;
              font-family: $interFont;
              font-size: 8px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }
          }
          .btnContainer {
            width: 100%;
            margin-top: 13px;
          }
        }
      }
    }
    .section2 {
      margin-top: 88px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      background: url('../../assets/frameBG.webp') no-repeat right bottom;
      background-size: contain;
      height: 385px;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      .textContainer {
        width: 241px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        h2 {
          text-align: center;
          font-family: $font;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
        p {
          color: $textColorDark;
          text-align: center;
          font-family: $font;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin-top: 20px;
        }
      }
    }
    .section3 {
      display: flex;
      padding-left: $mobilePadding;
      padding-right: $mobilePadding;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 70px;
      width: 100%;
      .card {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 339px;
        .secTitle {
          background-color: $headerBgColor;
          height: 74px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          h2 {
            text-align: center;
            color: $textColorLight;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: capitalize;
          }
        }
        .content {
          width: 100%;
          position: relative;
          .frontImg {
            position: absolute;
            top: 0;
            right: 0;
            width: 163px;
            height: auto;
          }
          .barcoding {
            width: 100%;
            background-color: rgba(39, 170, 225, 0.35);
            padding: 10px 163px 10px 17px;
          }
          .fraudDetection {
            width: 100%;
            background-color: rgba(39, 170, 225, 0.13);
            padding: 20px 163px 24px 17px;
          }
          .statistics {
            width: 100%;
            background-color: rgba(39, 170, 225, 0.04);
            padding: 25px 163px 40px 17px;
          }
        }
      }
    }
    .section4 {
      margin-top: 59px;
      padding-left: $mobilePadding;
      padding-right: $mobilePadding;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      h2 {
        text-align: center;
        font-family: $font;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        .textContainer {
          width: 186px;
          .streaming {
            margin-top: 24px;
          }
          p {
            margin-top: 9px;
            text-align: center;
            font-family: $font;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
        .imgContainer {
          margin-top: 50px;
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          img {
            width: 247px;
          }
        }
      }
    }
    .cta {
      margin-top: 52px;
      margin-bottom: 91px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      background-color: $headerBgColor;
      position: relative;
      padding: 34px 55px 73px;
      .semiCircles {
        position: absolute;
        top: 0;
        right: 0;
        width: 55px;
        height: auto;
      }
      .elispe {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 203px;
        height: auto;
      }
      .content {
        width: 100%;
        max-width: 400px;
        h2 {
          color: $matteWhite;
          text-align: center;
          font-family: $font;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
        p {
          margin-top: 2px;
          color: $textColorLight;
          text-align: center;
          font-family: $interFont;
          font-size: 9px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
        .btnsContainer {
          width: 100%;
          margin-top: 30px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          .btns {
            width: 195px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 3px;
          }
        }
      }
    }
  }
  @include largeBreakpoint {
    padding-right: 0;
    padding-left: 0;
    margin-top: 92px;
    margin-bottom: 165px;
    padding-top: 0;
    padding-bottom: 0;
    .scheduleDemoContainer {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .section1 {
        padding-left: $desktopPadding;
        padding-right: $desktopPadding;
        display: flex;
        gap: 30px;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        max-width: calc(#{$maxContentWidth} + 2 * #{$desktopPadding});
        .reasons {
          h2 {
            font-size: 42px;
          }
          ul {
            gap: 84px;
            max-width: 445px;
            li {
              grid-template-columns: 80px 1fr;
              gap: 24px;
              .iconContainer {
                height: 80px;
                width: 80px;
                border-radius: 40px;
                img {
                  width: 39px;
                  height: auto;
                }
              }
              span {
                font-size: 18px;
              }
            }
          }
        }
        .formContainer {
          margin-top: 0;
          form {
            border-radius: 40px;
            padding: 64px 64px 84px;
            max-width: none;
            width: 637px;
            gap: 21px;
            h3 {
              font-size: 38px;
            }
            label {
              font-size: 14px;
              textarea {
                font-size: 14px;
              }
            }
          }
        }
      }
      .section2 {
        margin-top: 252px;
        height: 800px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        .textContainer {
          width: 686px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          padding-left: $desktopPadding;
          h2 {
            text-align: center;
            font-family: $font;
            font-size: 48px;
            text-align: left;
          }
          p {
            color: $textColorDark;
            text-align: left;
            font-family: $font;
            font-size: 42px;
            margin-top: 29px;
          }
        }
      }
      .section3 {
        padding-left: $desktopPadding;
        padding-right: $desktopPadding;
        margin-top: 191px;
        .card {
          width: 100%;
          max-width: calc(1082px + 2 * #{$desktopPadding});
          padding: 64px 64px 84px;
          border-radius: 40px;
          .secTitle {
            height: 145px;
            h2 {
              font-size: 48px;
              text-transform: capitalize;
            }
          }
          .content {
            .frontImg {
              position: absolute;
              top: 0;
              right: 0;
              width: 318px;
              height: auto;
            }
            .barcoding {
              width: 100%;
              background-color: rgba(39, 170, 225, 0.35);
              padding: 67px 318px 31px 53px;
            }
            .fraudDetection {
              width: 100%;
              background-color: rgba(39, 170, 225, 0.13);
              padding: 38px 318px 75px 53px;
            }
            .statistics {
              width: 100%;
              background-color: rgba(39, 170, 225, 0.04);
              padding: 48px 318px 33px 53px;
            }
          }
        }
      }
      .section4 {
        margin-top: 251px;
        padding-left: $desktopPadding;
        padding-right: $desktopPadding;
        h2 {
          font-size: 48px;
          text-align: center;
        }
        .content {
          display: grid;
          grid-template-columns: 1fr 1fr;
          width: 100%;
          gap: 44px;
          .textContainer {
            order: 1;
            width: 100%;
            .streaming {
              font-size: 32px;
              text-align: left;
            }
            p {
              font-size: 24px;
              text-align: left;
            }
          }
          .imgContainer {
            order: 0;
            margin-top: 83px;
            img {
              width: 100%;
              max-width: 656px;
              height: auto;
            }
          }
        }
      }
      .cta {
        margin-top: 243px;
        margin-top: 251px;
        height: 672px;
        padding: 113px 178px 281px;
        .semiCircles {
          width: 178px;
        }
        .elispe {
          position: absolute;
          width: 679px;
        }
        .content {
          max-width: 890px;
          h2 {
            font-size: 48px;
          }
          p {
            font-size: 32px;
            margin-top: 6px;
          }
          .btnsContainer {
            margin-top: 104px;
            .btns {
              width: 430px;
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 28px;
            }
          }
        }
      }
    }
  }
}
