@import '../../scss/variables.scss';

.btnTitleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  .textContainer {
    p {
      font-family: $font;
      font-size: $font-size-base;
      font-style: normal;
      font-weight: $font-weight-semi-bold;
      line-height: normal;
    }
    span {
      font-family: $interFont;
      font-size: $font-size-small;
    }
    font-style: normal;
    font-weight: $font-weight-normal;
    line-height: normal;
  }
  .imgContainer {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    img {
      width: 31.087px;
      height: auto;
    }
  }
  &:hover {
    .textContainer {
      p {
        font-weight: $font-weight-bold;
      }
      span {
        font-weight: $font-weight-semi-bold;
      }
    }
    .imgContainer {
      img {
        width: 50px;
        height: auto;
      }
    }
  }

  &.eventViewTickets {
    .imgContainer {
      display: none;
    }
    &:hover {
      .textContainer {
        p {
          font-weight: $font-weight-bold;
        }
        span {
          font-weight: $font-weight-semi-bold;
        }
      }
      .imgContainer {
        img {
          width: 50px;
          height: auto;
        }
      }
    }
  }
  @media screen and (max-width: 355px) {
    p {
      font-size: $font-size-small;
    }
    span {
      font-size: 10.5px;
    }
    img {
      width: 27.201125px;
      height: auto;
    }
  }
  @include largeBreakpoint {
    padding: 0;
    p {
      font-size: 1.25rem;
    }
    .imgContainer {
      img {
        width: 23px;
        height: 20.058px;
      }
    }
  }
}
