@import '../../scss/variables.scss';

.checkboxGroup {
  display: flex;
  gap: 8px;
  justify-content: flex-start;
  align-items: center;
  span {
    color: $textColorSemiDark;
  }
}
.eventsFilter {
  width: 100%;
  min-height: 100vh;
  padding: 42px 38px;
  background-color: $bgColor;
  height: 100vh;
  overflow-y: scroll;
  section {
    h3 {
      color: #191c1f;
      font-size: 16px;
      font-style: normal;
      font-weight: $font-weight-normal;
      line-height: $font-size-large;
      text-transform: uppercase;
    }
    .filterOpts {
      margin-top: $space-md;
      display: flex;
      flex-direction: column;
      gap: 12px;
      width: 100%;
      &.types {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 12px;
      }
      .locationOpts {
        display: flex;
        flex-direction: column;
        gap: $space-md;
      }
    }
    .divider {
      height: 2px;
      width: 100%;
      background-color: #e4e7e9;
      margin-top: 24px;
      margin-bottom: 24px;
    }
  }

  @include largeBreakpoint {
    padding: 0;
    background-color: transparent;
  }
}
