@import '../../scss/variables.scss';

.premiumSponsorCard {
  border-radius: $border-radius-lg;
  background-color: rgba(39, 170, 225, 0.4);
  background-image: url('../../assets/semi-elip-top.webp'),
    url('../../assets/semi-elip-bottom.webp');
  background-repeat: no-repeat, no-repeat;
  background-size: 106px auto, 106px auto;
  background-position: top 0px right 22px, bottom 0 left 27px;
  display: flex;
  padding: $space-sm $space-md $space-sm $space-lg;
  align-items: center;
  justify-content: center;
  gap: $space-sm;
  position: relative;
  z-index: 1;
  width: 215px;
  text-decoration: none;
  .txts {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    p {
      font-family: $interFont;
      color: $primaryColor;
      font-size: $font-size-small;
      font-style: italic;
      font-weight: $font-weight-extra-bold;
      line-height: normal;
    }
    span {
      font-size: $font-size-small;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: $textColorSemiDark;
    }
  }
  .img {
    img {
      width: 73px;
      height: auto;
    }
  }
  @include mediumBreakpoint {
    width: 310px;
    padding: $space-md $space-lg;
    gap: $space-lg;
    .txts {
      p {
        font-size: $font-size-base;
      }
      span {
        font-size: $font-size-base;
      }
    }
  }
}
