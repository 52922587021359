@import '../../scss/variables.scss';

label {
  display: flex;
  flex-direction: column;
  font-family: $interFont;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: $textColorLight;
  width: 100%;
  &.small {
    font-family: $interFont;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    input {
      font-size: 8px;
      margin-top: 5px;
    }
  }

  span {
    color: $danger;
  }
  input {
    margin-top: 6px;
    width: 100%;
    height: 39px;
    flex-shrink: 0;
    font-family: $interFont;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
    border: 1px solid $lightGrey;
    &.error {
      border: 2px solid $danger;
    }
    &.disabled {
      background-color: $lightBackground;
    }
  }
  .errorMsg {
    color: $danger;
    font-family: $interFont;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    display: flex;
    gap: 5px;
    padding-top: 2px;
    align-items: center;
    img {
      width: 11px;
      height: 11px;
    }
  }
  &.semiDark {
    color: $textColorSemiDark;
    font-weight: 500;
    .label {
      font-weight: 500;
      color: $textColorSemiDark;
    }
    input {
      color: $textColorSemiDark;
    }
  }
  @include largeBreakpoint {
    padding-left: 0;
    padding-right: 0;
    &.small {
      font-size: 14px !important;
      input {
        font-size: 14px;
      }
    }
  }
}
