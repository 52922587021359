@import '../../scss/variables.scss';

.aboutEventPerfomance {
  margin-top: $space-lg;
  padding: 0 $mobilePadding;
  width: 100%;
  .aboutEventPerfomanceContainer {
    h2 {
      text-align: center;
      color: $textColorSemiDark;
      text-align: center;
      font-family: $font;
      font-size: $font-size-large;
      font-style: normal;
      font-weight: $font-weight-extra-bold;
      line-height: normal;
      text-transform: capitalize;
    }
    .aboutEventContent {
      margin-top: $space-md;
      p {
        text-align: center;
        color: $textColorSemiDark;
        text-align: center;
        font-family: $font;
        font-size: $font-size-small;
        font-style: normal;
        font-weight: $font-weight-normal;
        line-height: normal;
      }
    }
  }

  @include smallBreakpoint {
    padding-left: $tabletPadding;
    padding-right: $tabletPadding;
  }

  @include mediumBreakpoint {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    .aboutEventPerfomanceContainer {
      h2 {
        max-width: 645px;
        font-size: $font-size-h2;
      }
      .aboutEventContent {
        p {
          max-width: 645px;
          font-size: $font-size-base;
        }
      }
    }
  }
  @include largeBreakpoint {
    width: 100%;
    padding-left: $desktopPadding;
    padding-right: $desktopPadding;
    .aboutEventPerfomanceContainer {
      width: 100%;
      max-width: $maxContentWidth;
      h2 {
        width: 100%;
        max-width: calc(#{$maxContentWidth} - 2 * #{$desktopPadding});
        font-size: $font-size-h1;
        text-align: left;
      }
      .aboutEventContent {
        margin-top: $space-lg;
        width: 100%;
        p {
          width: 100%;
          max-width: calc(#{$maxContentWidth} - 2 * #{$desktopPadding});
          font-size: $font-size-base;
          text-align: left;
        }
      }
    }
  }
}
