@import '../../scss/variables.scss';
.social {
  margin-top: $space-lg;
  padding: 0 $mobilePadding;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  .socialContainer {
    width: 100%;
    h3 {
      color: $textColorSemiDark;
      font-family: $font;
      font-size: $font-size-base;
      font-style: normal;
      font-weight: $font-weight-extra-bold;
      line-height: normal;
      text-transform: uppercase;
      text-align: center;
    }
    .socialLinks {
      display: flex;
      width: 100%;
      gap: $space-md;
      justify-content: center;
      margin-top: $space-md;
    }
  }
  @include smallBreakpoint {
  }

  @include mediumBreakpoint {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .socialContainer {
      max-width: 645px;
      h3 {
        font-size: $font-size-large;
      }
    }
  }

  @include largeBreakpoint {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .socialContainer {
      max-width: $maxContentWidth;
      h3,
      .socialLinks,
      .support {
        width: 100%;
      }
      h3 {
        width: 100%;
        font-size: $font-size-large;
        text-align: left;
        font-size: $font-size-h1;
      }
      .socialLinks {
        justify-content: flex-start;
      }
    }
  }
}
