@import '../../scss/variables.scss';

.imagesContainer {
  background-color: rgba(130, 133, 152, 0.2);
  margin-top: $space-lg;
  padding: $space-md $mobilePadding;
  .images {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: $space-md;
    height: 135px;
    overflow-x: scroll;
    img {
      height: 100px;
    }
  }
  @include smallBreakpoint {
  }
  @include mediumBreakpoint {
    width: 100%;
    margin-top: $space-lg;
    padding-left: $tabletPadding;
    padding-right: $tabletPadding;
    .images {
      gap: $space-md;
      height: 200px;
      img {
        height: 170px;
      }
    }
  }
  @include largeBreakpoint {
    background-color: transparent;
    margin-top: $space-lg;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    gap: $space-md;
    overflow-x: scroll;
    .images {
      height: 495px;
      width: 100%;
      max-width: $maxContentWidth;
      img {
        height: 495px;
      }
    }
  }
}
