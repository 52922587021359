@import '../../scss/variables.scss';

.urlVideoPlayer {
  position: relative;
  height: auto;
  width: auto;
  p {
    text-align: center;
    font-family: $font;
  }
}
