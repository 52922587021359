@import '../../scss/variables.scss';

.selectInput {
  display: flex;
  flex-direction: column;
  width: 100%;
  label {
    font-family: $interFont;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: $textColorSemiDark;
    width: 100%;
  }
  select {
    border: 1px solid $lightGrey;
    border-radius: 5px;
    height: 39px;
    padding-left: 10px;
    padding-right: 10px;
    font-family: $interFont;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: $textColorSemiDark;
    width: 100%;
    margin-top: 6px;
  }
}
