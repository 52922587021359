@import '../../scss/custom.scss';

.languagePicker {
  position: relative;
  display: inline-block;
  .btn {
    display: flex;
    padding: 7px 9px 7px 15px;
    justify-content: flex-end;
    align-items: center;
    gap: 6px;
    flex-shrink: 0;
    border-radius: 20px;
    border: none;

    background-color: $bgColor;
    text-transform: uppercase;
    width: 92px;
    height: 34px;
    padding: 0;
    margin: 0;
    align-items: center;
    justify-content: center;
    position: relative;
    span {
      margin: 0;
      padding: 0;
      color: $lightGrey;
      font-size: 12px;
      font-weight: 400;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      img {
        width: 20px;
        height: 20px;
      }
    }
    &:hover {
      background-color: $bgColor;
    }
    &:active {
      background-color: $bgColor;
    }
  }

  .modal {
    display: none;
    background-color: $languagePickerBgColor;
    width: 100%;
    height: auto;
    position: absolute;
    top: calc(100% + 10px);
    left: 0;
    border-radius: 15px;
    z-index: 3000;
    padding: 0;
    &.active {
      display: block;
    }

    .btnsContainer {
      display: grid;
      background-color: $bgColor;
      border-radius: 10px;
      .changeBtn {
        border-radius: 10px;
        text-align: left;
        text-transform: uppercase;
        width: 100%;
        background-color: transparent;
        color: $primaryColor;
        padding: 10px 5px 10px 15px;
        border: none;
        font-size: 12px;
        color: $lightGrey;
        height: 35px;
        display: flex;
        align-items: center;
        gap: 10px;
        &:hover {
          background-color: $primaryColor;
          border: none;
          color: $textColorLight;
        }
        .flagImg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}
