@import '../../scss/variables.scss';

.signUp {
  background: url('../../assets/banner.jpg') no-repeat center center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 75px 0;
  min-height: calc(100vh - (#{$headerMobileHeight} + #{$mobileFooterHeight}));
  .signUpCard {
    background-color: $headerBgColor;
    width: 425px;
    max-width: 100%;
    flex-shrink: 0;
    border-radius: 5px;
    padding: 31px 35px 96px;
    form {
      padding-top: 22px;
      gap: 23px;
      display: flex;
      flex-direction: column;
    }
    .legalContainer {
      display: flex;
      flex-direction: column;
      gap: 16px;
      .legalLabel {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
        color: $textColorLight;
        font-family: $interFont;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        span {
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 19.6px */
          letter-spacing: -0.28px;
          color: $textColorLight;
          button {
            color: $primaryColor;
            background-color: transparent;
            border: none;
            cursor: pointer;
          }
        }
      }
      .legal {
        color: $textColorLight;
        font-family: $interFont;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding-bottom: 9px;
      }
    }
    .signUpBtn {
      height: 40px;
      flex-shrink: 0;
      background-color: $primaryColor;
      color: $textColorLight;
      font-family: $interFont;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      letter-spacing: -0.28px;
      line-height: normal;
      border: none;
      border-radius: 5px;
      margin-bottom: 16px;
    }
  }
  @include largeBreakpoint {
    padding-left: 0;
    padding-right: 0;
    min-height: calc(100vh - (#{$headerHeight} + #{$desktopFooterHeight}));
  }
}
