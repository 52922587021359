@import '../../scss/variables.scss';
.modalBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1040;
  &.hide {
    display: none;
  }
  .modal {
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    display: flex;
    flex-direction: column;
    transform: translate(-50%, -50%);
    max-width: 90%;
    min-width: 300px;
    background: $modalBgColor;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    overflow-y: auto;
    z-index: 1050;
    align-items: flex-end;
    max-height: 80vh;
    &.white {
      background-color: $bgColor;
      color: $textColorDark;
      border: none;
      box-shadow: none;
      padding: 50px;
    }
    &.whiteNoBtn {
      background-color: $bgColor;
      color: $textColorDark;
      border: none;
      box-shadow: none;
      padding: 50px;
      min-width: 350px;
    }
    .btnContainer {
      .closeBtn {
        display: none;
      }
    }
    .modalBody {
      width: 100%;
      max-height: 80vh;
      display: flex;
      flex-direction: column;
      overflow-y: scroll;
    }
    &.contactInfoSaved,
    &.loginInfoSaved {
      top: calc(#{$headerHeight} + 20px);
      left: 50%;
      width: 300px;
      max-width: 80%;
      // transform: translate(50%, 50%);
      border-radius: 10px;
      padding: 38px 38px 38px 42px;
    }
    &.selectTickets {
      width: 100vw;
      min-height: 100vh;
      max-width: 100%;
      background-color: transparent;
      padding: 0;
      .btnContainer {
        display: none;
        .modalBody {
          width: 100%;
          height: 100%;
        }
      }
    }
    &.transferTickets {
      width: 100vw;
      min-height: 100vh;
      max-width: 100%;
      background-color: $bgColor;
      padding: $space-lg $space-xl;
      .btnContainer {
        display: none;
        .modalBody {
          width: 100%;
          height: 100%;
        }
      }
    }

    @include mediumBreakpoint {
      &.contactInfoSaved,
      &.loginInfoSaved {
        top: calc(#{$headerHeight} + 30px);
        left: none;
        right: $tabletPadding;
        width: 367px;
        max-width: 80%;
        transform: translate(0, 0);
        border-radius: 10px;
        padding: 38px 38px 38px 42px;
      }
    }
    @include largeBreakpoint {
      padding: 20px 33px 125px 60px;
      width: 500px;
      flex-shrink: 0;
      .btnContainer {
        .closeBtn {
          display: block;
          font-size: 20.8px;
          border: none;
          padding: 0;
          margin: 0;
          background-color: transparent;
          color: $textColorLight;
        }
      }
      &.white {
        padding: 40px 38px 204px 95.5px;
        width: 800px;
        align-items: flex-start;
        .btnContainer {
          display: flex;
          width: 100%;
          justify-content: flex-end;
          .closeBtn {
            display: block;
            font-size: 20.8px;
            border: none;
            padding: 0;
            margin: 0;
            background-color: transparent;
            color: #707070;
            margin-bottom: 30px;
          }
        }

        .modalBody {
          width: 100%;
          max-width: 606px;
        }
      }

      &.whiteNoBtn {
        padding: 70px 98px 96px 89px;
        width: 800px;
        align-items: flex-start;
        .btnContainer {
          display: flex;
          width: 100%;
          justify-content: flex-end;
          .closeBtn {
            display: none;
          }
        }
        .modalBody {
          width: 100%;
          max-width: 606px;
        }
      }
      &.contactInfoSaved,
      &.loginInfoSaved {
        top: calc(#{$headerHeight} + 30px);
        left: none;
        right: $tabletPadding;
        width: 429px;
        padding: 16px 20px 38px 42px;
        align-items: flex-start;
        .btnContainer {
          display: flex;
          width: 100%;
          justify-content: flex-end;
        }
        .modalBody {
          max-width: 286px;
        }
      }
    }
  }
}
