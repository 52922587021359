@import '../../scss/variables.scss';

.verificationCard {
  background-color: $headerBgColor;
  width: 425px;
  max-width: 100%;
  flex-shrink: 0;
  border-radius: 5px;
  padding: 31px 35px 96px;
  h6 {
    color: $textColorLight;
    font-family: $interFont;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
  }
  form {
    padding-top: 22px;
    gap: 23px;
    display: flex;
    flex-direction: column;
  }
  .verifyBtn {
    height: 40px;
    flex-shrink: 0;
    background-color: $primaryColor;
    color: $textColorLight;
    font-family: $interFont;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border: none;
    border-radius: 5px;
    margin-bottom: 27px;
  }
}
