@import '../../../../scss/variables.scss';

.viewDetailsModal {
  width: 100%;
  .closeBtn {
    float: right;
    width: 24px;
  }
  .statusContainer {
    width: 100%;
    margin-top: $space-lg;
    padding: $space-lg;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
    margin-bottom: 20px;
    color: $textColorSemiDark;
    border-radius: $border-radius-sm;
    box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.1);
    &.transferred {
      border: 2px solid #f5efb9;
      background: #fefadc;
    }
    &.pendingTransfer {
      border: 2px solid #bcb0f5;
      background: #ebeeff;
    }
    &.readyToScan {
      border: 2px solid #219653;
      background: #e8f7eb;
    }

    .statusTitle {
      display: flex;
      gap: $space-md;
      align-items: center;
      .icon {
      }
      .title {
        font-family: $interFont;
        font-size: $font-size-large;
        font-style: normal;
        font-weight: $font-weight-semi-bold;
        line-height: 28px; /* 140% */
      }
    }
  }
  .performanceCard {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: $border-radius-sm;
    border: 0.5px solid #979797;
    padding: $space-md $space-lg;
    .event {
      color: $headerBgColor;
      font-family: $font;
      font-size: $font-size-small;
      font-style: normal;
      font-weight: $font-weight-normal;
      line-height: normal;
    }
    .performance {
      color: $headerBgColor;
      font-family: $font;
      font-size: $font-size-small;
      font-style: normal;
      font-weight: $font-weight-bold;
      line-height: normal;
    }
    .date {
      color: $headerBgColor;
      font-family: $font;
      font-size: $font-size-small;
      font-style: normal;
      font-weight: $font-weight-normal;
      line-height: normal;
      text-align: right;
    }
    .section {
      color: $headerBgColor;
      font-family: $font;
      font-size: $font-size-small;
      font-style: normal;
      font-weight: $font-weight-bold;
      line-height: normal;
    }
  }
}
