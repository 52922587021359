@import '../../../../scss/variables.scss';

.performanceCardHeader {
  display: flex;
  gap: $space-md;
  width: 100%;
  padding: 0 $space-md $space-md;
  .iconContainer {
    .toggleDetailsBtn {
      width: $space-lg;
      height: $space-lg;
      color: $headerBgColor;
      border-radius: $border-radius-lg;
      border: 2px solid #f5f6f7;
      background-color: $bgColor;
      &.active {
        background-color: $primaryColor;
        color: $textColorLight;
      }
    }
  }
  .data {
    width: 100%;
    .title {
      width: 100%;
      .description {
        display: flex;
        flex-direction: column;
        gap: $space-xs;
        width: 100%;

        h3 {
          color: $headerBgColor;
          font-family: $font;
          font-size: $font-size-small;
          font-style: normal;
          font-weight: $font-weight-bold;
          line-height: 20px;
        }
        p {
          color: $lightGrey;
          font-family: $font;
          font-size: $font-size-small;
          font-style: normal;
          font-weight: $font-weight-normal;
          line-height: 20px;
        }
      }
    }
    .info {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      span {
        color: $greyTextColor;
        font-family: $font;
        font-size: $font-size-small;
        font-style: normal;
        font-weight: $font-weight-bold;
        line-height: 20px;
      }
      button {
        display: flex;
        gap: $space-xs;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        border: none;
        color: $headerBgColor;
        font-family: $font;
        font-size: $font-size-small;
        font-style: normal;
        font-weight: $font-weight-bold;
        line-height: 20px;
        span {
          color: $primaryColor;
          font-size: $font-size-base;
        }
      }
    }
  }
  .info {
    width: 100%;
    display: flex;
    align-items: space-between;
    justify-content: center;
    gap: $space-md;
  }
}
