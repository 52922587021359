@import '../../scss/variables.scss';

.customRadioBtn {
  display: grid;
  grid-template-columns: 20px 1fr;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  button {
    border: solid 1px #c9cfd2;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: transparent;
    color: $textColorLight;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .label {
    color: $headerBgColor;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  @include largeBreakpoint {
    padding-left: 0;
    padding-right: 0;
    .label {
      font-size: 14px;
    }
  }
  &.eventFilter {
    align-items: center;
    .label {
      font-family: $interFont !important;
      color: $textColorDarkGrey !important;
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: 20px;
    }
  }
}
