@import '../../scss/variables.scss';

.contactUs {
  padding-bottom: 112px;
  background-color: $matteWhite;
  .titleContainer {
    padding: 50px $mobilePadding 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    h2 {
      color: $primaryColor;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-align: center;
    }
    p {
      color: $headerBgColor;
      text-align: center;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      width: 286px;
      padding-top: 11px;
    }
  }
  .contactContainer {
    padding-top: 59px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    .contactCard {
      width: 416px;
      height: auto;
      max-width: 100%;
      background: url('../../assets/bg-contact-us.webp') no-repeat;
      background-size: cover;
      border-radius: 15px;
      padding: 39px 54px 71px;
      h3 {
        color: $textColorLight;
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-align: center;
      }
      p {
        color: $greyTextColor;
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        font-family: $font;
      }
      .infoContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 111px;
        padding-bottom: 151px;
        gap: 50px;
        a {
          color: $textColorLight;
          font-size: 24px;
          text-decoration: none;
          display: flex;
          align-items: flex-start;
          justify-content: center;
          gap: 17px;
          text-align: center;
          span {
            color: $greyTextColor;
            font-size: 16px;
            text-align: center;
            &.address {
              margin-left: -20px;
            }
          }
        }
      }
    }
    .form {
      padding: 55px $mobilePadding 112px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      max-width: calc(416px + #{$mobilePadding * 2});
      gap: 20px;
      .nameContainer,
      .phoneContainer,
      .emailContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
        width: 100%;
        max-width: 100%;
      }
      h6 {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        text-align: left;
        width: 100%;
        padding-top: 40px;
      }
      .radioContainer {
        padding-top: 10px;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(134px, 1fr));
        gap: 20px;
        width: 100%;
        max-width: 100%;
        label {
          color: $textColorDarkGrey;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          justify-content: flex-start;
          gap: 10px;

          input {
            font-size: 20px;
            width: 20px;
            height: 20px;
            margin: 0;
          }
        }
      }
      .messageContainer {
        width: 100%;
        max-width: 100%;
        font-family: $font;
        .messageLabel {
          color: #8d8d8d;
          font: $font;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          padding-top: 57px;
          input {
            width: 100%;
            max-width: 100%;
            border-radius: 0;
            border-top: none;
            border-right: none;
            border-left: none;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            font-family: $font;
            border-bottom: 1px solid #8d8d8d;
            ::placeholder {
              color: #8d8d8d;
            }
          }
        }
      }
      .btnContainer {
        padding-top: 107px;
      }
    }
  }

  @include largeBreakpoint {
    .titleContainer {
      h2 {
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      p {
        width: auto;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
    .contactContainer {
      flex-direction: row;
      background-color: $bgColor;
      padding-top: 0;
      margin-top: 67px;
      padding: 11px;
      border-radius: 10px;
      gap: 53px;
      .contactCard {
        width: 693px;
        height: auto;
        padding: 40px 40px 71px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        .infoContainer {
          align-items: flex-start;
          a {
            text-align: left;
            span {
              max-width: 288px;
              text-align: left;
              &.address {
                margin-left: 0;
              }
            }
          }
        }
      }
      .form {
        padding: 0;
        padding-top: 50px;
        padding-bottom: 47px;
        gap: 21px;
        align-items: flex-start;
        max-width: none;
        .nameContainer,
        .emailContainer {
          flex-direction: row;
          justify-content: flex-start;
          gap: 9px;
          max-width: 509px;
        }
        .phoneContainer {
          max-width: 250px;
        }

        h6 {
          padding-top: 36px;
        }
        .radioContainer {
          padding-top: 8px;
          label {
            input {
            }
          }
        }
        .messageContainer {
          .messageLabel {
            padding-top: 30px;
            padding-bottom: 0;
          }
        }
        .btnContainer {
          display: flex;
          width: 100%;
          align-items: center;
          justify-content: flex-end;
          padding-top: 91px;
        }
      }
    }
  }

  @include xLargeBreakpoint {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    .contactContainer {
      //   width: 100%;
      max-width: $maxContentWidth;
    }
  }
}
