@import '../../scss/variables.scss';

.eventListCard {
  background-color: $headerBgColor;
  padding: 33px 27px 19px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 526px;
  border-radius: $border-radius-md;
  .cardContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    .alwaysVisible {
      .titleContainer {
        display: flex;
        gap: 27px;
        color: $matteWhite;
        width: 100%;
        img {
          width: 88px;
          height: 88px;
        }
        .textContainer {
          h3 {
            color: $primaryColor;
            font-family: $font;
            font-size: 24px;
            font-style: normal;
            font-weight: 800;
            line-height: normal;
          }
          h4 {
            color: $matteWhite;
            font-family: $font;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
          p {
            color: $matteWhite;
            font-family: $interFont;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }
      .buttonContainer {
        margin-top: 68px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 17px;
        span {
          color: $matteWhite;
          font-size: 12px;
          font-style: normal;
          font-weight: 300;
          line-height: normal;
        }
      }
    }
    .description {
      margin-top: 27px;
      color: $matteWhite;
      font-family: $interFont;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      width: 100%;
      text-align: left;
    }
    .toogleBtn {
      margin-top: 30px;
      background-color: transparent;
      border: none;
      outline: none;
      color: $matteWhite;
      font-size: 12px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      font-size: 27px;
    }
  }
  @include largeBreakpoint {
    padding: 36px 60px 36px 50px;
    max-width: 941px;
  }
  @include xLargeBreakpoint {
    .cardContainer {
      .alwaysVisible {
        display: flex;
        .titleContainer {
          img {
            width: 150px;
            height: 150px;
          }
          .textContainer {
            h3 {
              font-size: 32px;
            }
            h4 {
              font-size: 20px;
            }
            p {
              font-size: 16px;
            }
          }
        }
        .buttonContainer {
          max-width: 230px;
        }
      }
    }
  }
}
