@import '../../scss/variables.scss';

.selectTickets {
  width: 100vw;
  height: 100vh;
  background-color: $matteWhite;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .selectTicketsContainer {
    width: 100%;
    height: 100%;
    .header {
      position: relative;
      padding: $space-md $space-lg;
      background-color: $headerBgColor;
      height: 250px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      width: 100%;
      color: $textColorLight;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(42, 44, 58, 0.5);
        pointer-events: none;
        z-index: 1;
      }
      .textContainer,
      .btnContainer {
        position: relative;
        z-index: 2;
      }
      .btnContainer {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: $space-xl;
        button {
          background-color: transparent;
          border: none;
          color: $textColorLight;
          font-size: $font-size-large;
          cursor: pointer;
        }
      }
      .textContainer {
        h2 {
          font-size: $font-size-large;
          text-transform: uppercase;
          font-weight: $font-weight-extra-bold;
          text-align: center;
        }
        h3 {
          font-size: $font-size-base;
          text-transform: uppercase;
          font-weight: $font-weight-bold;
          text-align: center;
        }
      }
    }
    .selectContent {
      padding: $space-xl $mobilePadding;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .selectContentContainer {
        width: 100%;
        max-width: 400px;
        h2 {
          font-size: $font-size-large;
          font-weight: $font-weight-normal;
        }
      }
    }
    .btnContainer {
      // background-color: $bgColor;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: $space-md $space-lg;
    }
  }
}
