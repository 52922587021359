@import '../../scss/variables.scss';
.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 0;

  .loginLink {
    text-decoration: none;
    span {
      font-size: 14px;
      font-family: $specialFont;
      color: $primaryColor;
      padding: 0;
      margin: 0;
      font-weight: 700;
    }
    &:hover span {
      border-bottom: 1px dotted $primaryColor;
    }
  }

  .signUpLink {
    display: inline-flex;
    height: 41.5px;
    width: 110px;
    padding: 15px 25px;
    align-items: center;
    gap: 15px;
    flex-shrink: 0;
    color: $textColorLight;
    background-color: $primaryColor;
    font-size: 16px;
    font-weight: 500;
    border-radius: 40px;
    border: none;
    line-height: normal;
    &:hover {
      padding: 15px 23px;
      font-weight: 700;
      box-shadow: 4px 4px 4px 0px rgba(237, 226, 226, 0.25);
    }
  }
}
