.mapContainer {
  width: 100%;
  height: 100%;
  position: relative;
}

.map {
  width: 100%;
  height: 400px; // Adjust as needed
  border: 1px solid #ddd;
  border-radius: 8px;
}
