@import '../../scss/variables.scss';

.iconTextDemo {
  display: grid;
  grid-template-columns: 26px 1fr;
  gap: 16px;
  background-color: transparent;
  .icon {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    .imgContainer {
      background-color: #f6fafe;
      height: 26px;
      width: 26px;
      border-radius: 13px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 13px;
        height: auto;
      }
    }
  }
  .textContainer {
    h4 {
      color: #282d46;
      font-family: $font;
      font-size: 8px;
      font-style: normal;
      font-weight: 700;
      line-height: 182.602%;
    }
    p {
      color: #282d46;
      font-family: $font;
      font-size: 8px;
      font-style: normal;
      font-weight: 500;
      line-height: 182.602%;
    }
  }
  @include largeBreakpoint {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: 80px 1fr;
    gap: 19px;
    .icon {
      .imgContainer {
        height: 80px;
        width: 80px;
        border-radius: 40px;
        img {
          width: 35px;
          height: auto;
        }
      }
    }
    .textContainer {
      h4 {
        font-size: 18px;
      }
      p {
        font-size: 18px;
      }
    }
  }
}
