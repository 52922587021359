@import '../../scss/variables.scss';

.performanceCard {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  .blueStripe {
    position: absolute;
    left: $space-xs;
    top: 10%;
    width: $space-xs;
    height: 80%;
    background-color: $primaryColor;
  }
  .performanceCardContainer {
    width: 100%;
    border-radius: $border-radius-lg;
    background-color: #f2f2f2;
    padding: $space-md $space-lg $space-lg;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    .group {
      display: flex;
      gap: $space-sm;
      img {
        width: 100px;
        height: 100px;
      }
      .dataContainer {
        display: flex;
        flex-direction: column;
        gap: $space-sm;
        h3 {
          font-family: $font;
          font-size: $font-size-base;
          font-style: normal;
          font-weight: $font-weight-extra-bold;
          line-height: normal;
        }
        div {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          color: $textColorSemiDark;
          font-family: $font;
          font-size: $font-size-small;
          font-style: normal;
          font-weight: $font-weight-semi-bold;
          line-height: normal;
          gap: $space-sm;
          span {
            color: $textColorSemiDark;
            font-family: $font;
            font-size: $font-size-small;
            font-style: normal;
            font-weight: $font-weight-normal;
            line-height: normal;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: $space-sm;
            &.icon {
              color: $primaryColor;
              font-size: $font-size-large;
            }
          }
        }
      }
    }
    .btnContainer {
      margin-top: $space-lg;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
    }
  }

  @include smallBreakpoint {
    padding-left: 0;
    padding-right: 0;
    .performanceCardContainer {
      padding-left: $space-lg;
      padding-right: $space-lg;
      max-width: 550px;
      .group {
        gap: $space-md;
      }
    }
  }
  @include largeBreakpoint {
    padding-left: 0;
    padding-right: 0;
    justify-content: flex-start;
    align-items: flex-start;
    // width: 100%;
    .performanceCardContainer {
      padding: $space-lg $space-xl;
      display: grid;
      grid-template-columns: 1fr 250px;
      max-width: calc(1145px - 2 * #{$space-lg});
      .group {
        gap: $space-lg;
        img {
          width: 150px;
          height: 150px;
        }
        .dataContainer {
          h3 {
            font-size: $font-size-h1;
          }
          div {
            span {
              font-size: $font-size-base;
              &.icon {
                font-size: $font-size-large;
              }
            }
          }
        }
      }
    }
  }
}
