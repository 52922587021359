@import '../../scss/variables.scss';

.cartData {
  display: flex;
  flex-direction: column;
  gap: $space-md;
  width: 100%;
  .bottomContainer {
    display: flex;
    flex-direction: column;
    gap: $space-md;
    width: 100%;
    .totalContainer {
      p {
        font-size: $font-size-base;
        text-align: right;
        font-weight: $font-weight-semi-bold;
      }
    }
    .btnsContainer {
      margin-top: $space-md;
      display: flex;
      flex-direction: column;
      gap: $space-md;
      width: 100%;
      justify-content: center;
      align-items: center;
      .cancelBtnContainer,
      .checkoutBtnContainer {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
      .cancelBtnContainer {
        order: 2;
      }
    }
  }
}
