@import '../../scss/variables.scss';

.accordion {
  padding-left: 40px;
  border: 2px solid $modalBgColor;
  border-radius: 8px;
  max-width: 811px;
  .accordionContainer {
    .accordionTitleContainer {
      display: grid;
      grid-template-columns: 20px 1fr 60px;
      gap: 21px;
      .number {
        font-style: normal;
        font-size: 18px;
        font-weight: 900;
        line-height: normal;
        color: $extraDarkBlue;
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
      }
      .title {
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        color: $extraDarkBlue;
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
      }
      button {
        border: none;
        background: none;
        background-color: $extraDarkBlue;
        width: 60px;
        min-height: 60px;
        height: 100%;
        border-radius: 0px 5px 5px 0px;

        &.isOpen {
          background-color: $primaryColor;
        }
        img {
          width: 22px;
          height: 22px;
          object-fit: contain;
        }
      }
    }
    .accordionContentContainer {
      display: none;
      &.isOpen {
        display: block;
      }
      .content {
        color: $textColorDark;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: 36px;
        padding: 20px 39px 32px 67px;
        padding-top: 20px;
        padding-left: 67px;
        padding-right: 39px;
      }
    }
  }

  @include largeBreakpoint {
    padding: 0;
    padding-left: 40px;
    border: 2px solid $modalBgColor;
    border-radius: 8px;
    max-width: 811px;
    .accordionContainer {
      .accordionTitleContainer {
        display: grid;
        grid-template-columns: auto 1fr 86px;
        gap: 10px;
        .number {
          font-style: normal;
          font-size: 24px;
          font-weight: 900;
          line-height: normal;
          color: $extraDarkBlue;
          display: flex;
          height: 100%;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
        }
        .title {
          font-family: Poppins;
          font-size: 22px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          color: $extraDarkBlue;
          display: flex;
          height: 100%;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
        }
        button {
          border: none;
          background: none;
          background-color: $extraDarkBlue;
          width: 86px;
          height: 80px;
          border-radius: 0px 5px 5px 0px;

          &.isOpen {
            background-color: $primaryColor;
          }
          img {
            width: 22px;
            height: 22px;
            object-fit: contain;
          }
        }
      }
      .accordionContentContainer {
        display: none;
        &.isOpen {
          display: block;
        }
        .content {
          color: $textColorDark;
          font-size: 16px;
          font-style: normal;
          font-weight: 300;
          line-height: 36px;
          padding: 20px 39px 32px 67px;
          padding-top: 20px;
          padding-left: 67px;
          padding-right: 39px;
        }
      }
    }
  }
}
