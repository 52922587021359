@import '../../scss/variables.scss';

.btnContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  button {
    background-color: $textColorLight;
    flex-shrink: 0;
    border-radius: 5px;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.28px;
    color: $headerBgColor;
    border: none;
    height: 48px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;

    img {
      width: 20px;
      height: 20px;
    }
  }
}
