@import '../../scss/variables.scss';

.ticketSection {
  background: url('../../assets/section-ticket.svg');
  background-size: contain;
  background-repeat: no-repeat;
  min-height: 400px;
  padding: $space-lg 48px;
  .ticketSectionContainer {
    width: 100%;
    max-width: 100%;
    max-width: 400px;
    .titleLine {
      width: 100%;
      max-width: 400px;
      display: grid;
      grid-template-columns: 3fr 1fr;
      gap: $space-xl;
      margin-bottom: $space-md;
      span {
        color: $textColorLight;
        font-family: $font;
        font-size: $font-size-base;
        font-style: normal;
        font-weight: $font-weight-semi-bold;
        line-height: normal;
        &.sectionName {
          max-width: 180px;
        }
      }
    }
    .ticketLine {
      width: 100%;
      max-width: 400px;
      display: grid;
      grid-template-columns: 3fr 1fr;
      gap: $space-xl;
      margin-bottom: $space-md;
      span,
      p {
        color: $textColorLight;
        font-family: $font;
        font-size: $font-size-small;
        text-transform: capitalize;
        font-style: normal;
        font-weight: $font-weight-semi-bold;
        line-height: normal;
        &.ticketName {
          max-width: 180px;
          background-color: $primaryColor;
          padding: $space-sm $space-md;
          border-radius: $border-radius-lg;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        &.ticketQuantity {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          input {
            width: 100%;
            // max-width: 50px;
          }
        }
      }
    }
  }
}
