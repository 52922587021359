@import '../../scss/variables.scss';

.eventDashboard {
  padding: 17px $mobilePadding 30px;
  .eventDashboardContainer {
    .topContainer {
      width: 100%;
      .searchContainer {
        display: flex;
        width: 100%;
        height: 45px;
        input {
          height: 100%;
          padding: $space-sm $space-lg;
          border-radius: 2.5rem 0px 0px 2.5rem;
          border: 1px solid #e6e6e6;
          color: $textColorSemiDark;
          font-family: $specialFont;
          font-size: $font-size-base;
          font-style: normal;
          font-weight: $font-weight-normal;
          line-height: $font-size-h1;
          letter-spacing: 0.2px;
        }
        button {
          height: 100%;
          color: $textColorLight;
          border-radius: 0px 2.5rem 2.5rem 0px;
          border: 1px solid $lightBorderGrey;
          background: $primaryColor;
          display: flex;
          width: 60px;
          padding: $space-sm $space-lg $space-sm 0.74rem;
          align-items: center;
          flex-shrink: 0;
          font-size: 1.25rem;
        }
      }
      .pathContainer {
        display: flex;
        align-items: center;
        a {
          margin: 10px 24px;
          text-align: center;
          font-family: $font;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-decoration: none;
          color: #535353;
          &:hover {
            border-bottom: $hoverBorder;
          }
          &:first-child {
            margin-left: 0;
          }
        }
        span {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .dataSection {
      .filtersContainer {
        width: 350px;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        animation: slideIn 0.2s ease-out forwards;
        display: none;
        &.showFilter {
          display: block;
        }
      }
      .dataContainer {
        .sortContainer {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 15px;

          span {
            color: $textColorDark;
            font-family: $specialFont;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px; /* 200% */
            letter-spacing: 0.2px;
          }
        }
        .eventList {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
          gap: 24px;
          margin-top: 22px;
          position: relative;
        }
      }
    }
  }
  @include smallBreakpoint {
  }
  @include largeBreakpoint {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    .eventDashboardContainer {
      width: 100%;
      max-width: calc(1440px - 2 * #{$desktopPadding});
      .topContainer {
        display: grid;
        grid-template-columns: 300px 1fr;
        gap: 40px;
        .searchContainer {
          order: 2;
        }
        .pathContainer {
          display: flex;
          align-items: center;
          a {
            font-size: 16px;

            &:hover {
              border-bottom: $hoverBorder;
            }
            &:first-child {
              margin-left: 0;
            }
          }
          span {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
      .dataSection {
        margin-top: $space-lg;
        display: grid;
        gap: 40px;
        grid-template-columns: 300px 1fr;
        .filtersContainer {
          width: 300px;
          position: static;
          display: block;
          z-index: 0;
          animation: none;
        }
        .dataContainer {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          .sortContainer {
            width: 100%;
            max-width: 941px;
            justify-content: space-between;
            gap: 15px;
            .btnContainer {
              display: none;
            }

            span {
              font-size: 14px;
            }
          }
          .eventList {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            gap: 24px;
            margin-top: 22px;
            position: relative;
          }
        }
      }
    }
  }
}

@keyframes slideIn {
  0% {
    transform: translateX(-350px);
  }
  100% {
    transform: translateX(0);
  }
}
