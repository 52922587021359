@import "../../../scss/variables.scss";

.ticketInfo {
  .ticketNumberContainer {
    display: flex;
    gap: $space-sm;
  }
  .ticketInfoContainer {
    display: grid;
    grid-template-columns: 3fr 1fr 1fr;
    gap: $space-sm;
    .ticketInfoCol {
      display: flex;
      flex-direction: column;
      .ticketInfoHeader {
        font-size: $font-size-small;
        text-transform: uppercase;
        font-weight: $font-weight-light;
        font-family: $interFont;
        color: $iceWhite;
      }
      .ticketInfoData {
        font-size: $font-size-small;
        font-weight: $font-weight-semi-bold;
      }
    }
  }
}
