//  created by satwinder singh on 10/6/2022
/* The following block can be included in a custom.scss */
@import 'mixins';
@import './variables.scss';

/* make the customizations */
// $primary: #79a83b;
// $primary: #27aae1;
// $primaryDark: #79a83b;
// $primaryLight: #b2d882;
// $secondary: #1982c4;
// $secondaryDark: #19618d;
// $secondaryGrayer: #1982c4;
// $grey: #6b6b6b;
// $lightGrey: #f8f8f8;
// $orange: #cd671b;
// $light: #f5f5f5;
// $gray: #c4cbd0;
// $lightGray: #e1e2e3;
// $darkGray: #707070;
// $info: #1982c4;
// $danger: #ed3237;
// $dark: #353a3b;
// $white: #ffffff;
// $success: #79a83b;
// $bgColor: #2a2c3a;

// $theme-colors: (
//   'primary': $primary,
//   'primaryDark': $primary,
//   'primaryLight': $primaryLight,
//   'secondary': $secondary,
//   'secondaryDark': $secondaryDark,
//   'secondaryGrayer': $secondaryGrayer,
//   'orange': $orange,
//   'light': $light,
//   'gray': $gray,
//   'info': $info,
//   'danger': $danger,
//   'dark': $dark,
//   'lightGray': $lightGray,
//   'darkGray': $darkGray,
//   'white': $white,
//   'success': $success,
// );

/* make changes to the !default Bootstrap variables */
// $body-color: #4d5152;

// Fonts -----------------------------
// $font: 'Noto Sans', Helvetica, arial, sans-serif;
// $font: 'Poppins', sans-serif;
// $specialFont: 'Montserrat', sans-serif;
// $font-icon: 'Font Awesome 5 Free';

/* import bootstrap to set changes */
// @import '~bootstrap/scss/bootstrap';

// import scss for react-datepicker
@import '~react-datepicker/dist/react-datepicker';

// import css for slideshow element
@import 'react-slideshow-image/dist/styles.css';

// $dnd-banner-width: 100%;
// $dnd-banner-height: 20rem;

// $dnd-banner-width-sm: 100%;
// $dnd-banner-height-sm: 15rem;

html {
  font-size: 14px;
  font-family: $font;
  body {
    color: $textColorDark;
    background: $bgColor;
  }
}

// .logo {
//   background: var(--logo);
//   width: 22rem;
//   height: 4rem;
//   background-size: contain;
//   background-position: left;
//   background-repeat: no-repeat;
//   padding-left: 8rem;
//   color: var(--txt-color);

//   &:hover {
//     color: var(--txt-color);
//   }
// }

// .cursor-pointer {
//   cursor: pointer !important;
// }

// #root {
//   font-family: $font;
//   color: var(--txt-color);
//   background: $bgColor;
//   height: 100vh;
//   // overflow: auto;

//   #banner {
//     background-image: var(--banner);
//     background-repeat: no-repeat;
//     background-size: cover;
//     background-position: center center;
//   }
// }

// .homebanner {
//   width: 100%;
//   height: 378px;
//   background-image: var(--banner);
//   background-repeat: no-repeat;
//   background-size: cover;
//   background-position: center center;
//   display: flex;
//   flex-direction: column;

//   &.promoter-banner {
//     background-image: url('../../public/promoter-dashboard-banner.png');
//   }

//   .profile-subanner {
//     position: absolute;
//     bottom: -5rem;
//     left: 15rem;

//     .profile-img {
//       position: absolute;
//       left: -2.5rem;
//       top: -2.5rem;
//       width: 15rem;
//       height: 15rem;
//     }
//   }

//   .social-links {
//     position: absolute;
//     bottom: -40px;
//     right: 35%;
//   }
// }

// @media screen and (max-width: 768px) {
//   .homebanner {
//     // to adjust on promoter dashboard page
//     .profile-subanner {
//       left: 8rem;
//     }
//   }

//   .profile-subanner {
//     position: relative;
//     padding-top: 20px;

//     .profile-img {
//       position: absolute;
//       margin-top: -1.25rem;
//       left: calc(50% - 75px) !important;
//       bottom: 80% !important;
//       top: unset !important;
//       width: 150px !important;
//       height: 150px !important;
//     }
//   }
// }

// .text-default {
//   color: var(--txt-color) !important;
// }

// .modal {
//   .modal-content {
//     color: var(--txt-color);
//     background-color: var(--bg-color);

//     .btn-close {
//       // color: var(--txt-color);
//       filter: var(--bs-btn-close-white-filter);
//     }
//   }
// }

// .toast-container {
//   z-index: $zindex-popover;
// }

// a {
//   text-decoration: none;
//   color: rgba($primary, 1);
//   @include fade(0.3s);

//   > i {
//     margin-right: 0.5rem;
//   }

//   &:hover {
//     color: rgba($primary, 0.7);
//   }

//   &.secondary {
//     color: rgba($secondary, 1);

//     &:hover {
//       color: rgba($secondary, 0.7);
//     }
//   }
// }

// .required:after {
//   content: ' *';
//   color: $danger;
// }

// .form-check-input {
//   &:checked {
//     background-color: $info;
//     border-color: $info;
//   }

//   &:focus {
//     border-color: #86b7fe;
//     box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
//   }
// }

// .navbar-light,
// .navbar-dark {
//   .navbar-nav .nav-link {
//     color: $primary;
//     padding-right: 1rem;
//     padding-left: 1rem;
//   }

//   .navbar-nav .nav-link:hover,
//   .navbar-nav .nav-link:focus {
//     color: $primaryDark;
//   }
// }

// // custom css for bootstrap pagination

// .page-link {
//   color: var(--txt-color);
//   border: 1px solid var(--txt-color);
//   margin: 1rem;
//   border-radius: 50%;
//   padding: 0.375rem 0.85rem;
//   background-color: transparent;
// }

// .page-item.disabled .page-link {
//   background-color: transparent;
// }

// .page-item.active .page-link {
//   background-color: #1976d2;
//   border-color: #1976d2;
// }

// .page-item:first-child .page-link,
// .page-item:last-child .page-link {
//   border-radius: 50%;
// }

// .page-link:focus {
//   box-shadow: none;
// }

// .page-item-prev .page-link,
// .page-item-next .page-link {
//   border: none;
// }

// body {
//   .dropdown {
//     .dropdown-toggle {
//       &:not(.user-toggle) {
//         color: var(--txt-color);
//         background-color: var(--user-dropdow-bg);
//         border: var(--user-dropdow-border) solid 0.1rem;
//       }
//       &:focus {
//         box-shadow: none !important;
//       }
//     }

//     .dropdown-menu {
//       background-color: var(--user-dropdow-bg);
//       border: var(--user-dropdow-border) solid 0.1rem;

//       .dropdown-item {
//         color: var(--txt-color);
//         &:focus,
//         &:hover {
//           background-color: transparent;
//           color: $info;
//         }
//       }
//     }
//   }

//   .logo-in-center {
//     position: relative;

//     &::after {
//       content: '';
//       background: var(--brandicon);
//       position: absolute;
//       width: 136px;
//       height: 70px;
//       background-position: center;
//       background-repeat: no-repeat;
//       left: calc(50% - 68px);
//       top: 4rem;
//     }
//   }

//   @media (max-width: 768px) {
//     .logo-in-center {
//       &::after {
//         transform: scale(0.75) rotate(90deg);
//         top: calc(50% - 35px);
//       }

//       & > .section {
//         min-height: 400px;
//       }
//     }
//   }
// }

// /*
// CSS for the header
// */

// header.main {
//   .navbar {
//     .navbar-toggler {
//       color: $white;
//       border-color: $grey;
//       .hamburger {
//         display: none;
//       }
//       .close {
//         display: inline-block;
//       }
//       &.collapsed {
//         .hamburger {
//           display: inline-block;
//         }
//         .close {
//           display: none;
//         }
//       }
//     }
//   }
// }

// @media (max-width: 991px) {
//   #responsive-navbar-nav {
//     background-color: $bgColor;
//     width: calc(100% + (2 * 42px)) !important;
//     padding-bottom: 20px;
//   }
// }

// @media (max-width: 768px) {
//   header.main {
//     .navbar {
//       .navbar-toggler:not(.collapsed) {
//         .fa-circle-xmark {
//           display: inline-block;
//         }

//         .navbar-toggler-icon {
//           display: none;
//         }
//       }

//       .navbar-collapse {
//         & > .navbar-nav {
//           gap: 15px;
//         }
//       }
//     }
//   }
// }

// header.main {
//   background-color: #2a2c3a;
//   border-bottom: #1a1b24 solid 0.1rem;
//   box-shadow: 0 2px 8px 0 var(--shadow);

//   nav {
//     .logo {
//       background: var(--logo);
//       width: 22rem;
//       height: 4rem;
//       background-size: contain;
//       background-position: left;
//       background-repeat: no-repeat;
//       padding-left: 8rem;
//       color: var(--txt-color);

//       &:hover {
//         color: var(--txt-color);
//       }
//     }

//     &.navbar-light,
//     &.navbar-dark {
//       .navbar-nav {
//         .nav-link {
//           color: $white;
//           padding-left: 0;
//           padding-right: 0;
//           margin: 0 14px;

//           &.active {
//             color: $primary;
//             border-bottom: 2px solid $primary;
//           }
//         }
//       }
//     }

//     .user-menu {
//       .dropdown-toggle {
//         &.user-toggle {
//           padding: 0;
//         }

//         &:after {
//           display: none;
//         }
//       }
//     }

//     .user {
//       margin: 0 0 0 0.5rem;
//       position: relative;

//       &.vip::before {
//         position: absolute;
//         content: 'VIP';
//         width: 1.7rem;
//         background-color: rgba($info, 1);
//         color: rgba($white, 1);
//         font-size: 0.56rem;
//         font-weight: bold;
//         padding: 0.14rem 0.35rem;
//         border-radius: 0.21rem;
//         top: -0.35rem;
//         right: -0.7rem;
//       }

//       .avatar {
//         border-radius: 3.5rem;
//         border: rgba($white, 1) 0.14rem solid;
//         width: 3.5rem;
//         height: 3.5rem;
//         overflow: hidden;
//         box-shadow: 2px 2px 4px 1px var(--shadow);
//         cursor: pointer;

//         img {
//           min-width: 3.5rem;
//           min-height: 3.5rem;
//           width: 100%;
//           height: 100%;
//         }
//       }
//     }
//   }
// }

// /*
// CSS for the footer
// */
// footer.main {
//   background-color: #2a2c3a;
//   border-bottom: #1a1b24 solid 0.1rem;
//   padding: 1.5rem;
// }

// .login-box,
// .register-box {
//   width: 360px;
//   background-color: var(--login-bg);
//   border: 1px solid var(--login-border);
//   color: var(--txt-color);
// }

// .chat-bg {
//   background-color: var(--chat-bg);
// }

// .bg-header {
//   background-color: var(--header-bg-color);
//   border-bottom: var(--header-border) solid 0.1rem;
//   @include fade(0.3s);
// }

// .bg-hover:hover {
//   background-color: $primary;
// }

// .btn-upload-icon {
//   background-color: $primary;
//   padding: 6px 8px;
//   border-radius: 50%;
//   position: absolute;
//   bottom: 10px;
//   right: 10px;
// }

// .event-header,
// .event-footer {
//   height: 177px;
//   position: relative;
//   background-repeat: no-repeat;
//   background-position: center;
//   border: 1px solid $primary;
// }

// .event-header {
//   background-image: url('../../public/event_header.png');
// }

// .event-footer {
//   background-image: url('../../public/event_footer.png');
// }

// .vertical-nav-tabs {
//   border-right: 1px solid $darkGray;

//   &.acc-setup-nav-tabs {
//     .nav-link {
//       min-width: 200px;
//     }
//   }

//   .nav-link {
//     padding: 1.4rem 1rem;
//     color: var(--txt-color);
//     font-weight: 600;
//     min-width: 150px;
//   }

//   .nav-link.active {
//     background-color: $primary;
//   }
// }

// @media (max-width: 768px) {
//   .drag-drop-container,
//   .event-banner-image {
//     height: $dnd-banner-height-sm !important;
//     width: $dnd-banner-width-sm !important;
//   }

//   .fs-sm-1 {
//     font-size: 2rem;
//   }

//   .vertical-nav-tabs {
//     &.acc-setup-nav-tabs {
//       .nav-link {
//         min-width: 50px;
//       }
//     }
//     .nav-link {
//       min-width: 50px;
//     }
//   }
// }

// @media (min-width: 768px) {
//   .h-md-100 {
//     height: 100%;
//   }
// }

// .btn.btn-link:focus {
//   box-shadow: none;
// }

// .btn,
// .btn-lg,
// .btn-group-lg > .btn {
//   border-radius: 0;
// }

// .btn-group > .btn {
//   background-color: $white;
//   border-color: $dark;
// }

// .btn-group > .btn.active {
//   color: $white;
//   background-color: $primary;
//   border-color: $primary;
// }

// .dropdown.show {
//   .dropdown-toggle {
//     .fa-chevron-down {
//       transform: rotate(180deg);
//     }
//   }
// }

// .dropdown-toggle {
//   .fa-chevron-down {
//     @include fade(0.3s);
//   }
// }

// .list-event-steps {
//   list-style-type: none;

//   li {
//     padding: 1rem 0;

//     &.checked {
//       &::before {
//         content: '\2713';
//         padding: 0px 3px;
//       }
//     }

//     &::marker {
//       display: none;
//     }

//     &::before {
//       content: counter(list-item);
//       color: white;
//       background: black;
//       border: 1px solid black;
//       border-radius: 50%;
//       padding: 0px 4px;
//       font-size: 10px;
//       margin-right: 5px;
//     }
//   }
// }

// /* css for drag and drop */

// .drag-drop-container {
//   height: $dnd-banner-height;
//   width: $dnd-banner-width;
//   max-width: 100%;

//   .display-image {
//     // width: 100%;
//     // height: 100%;

//     .delete-btn-wrapper {
//       width: 100%;
//       height: 100%;
//       display: flex;
//       align-items: center;
//       justify-content: center;
//       position: relative;
//       visibility: hidden;
//     }

//     &:hover {
//       .delete-btn-wrapper {
//         visibility: visible;
//         @include fade(0.5s);
//       }
//     }

//     .bg-fade {
//       background: #ffffff;
//       opacity: 0.3;
//       width: 100%;
//       height: 100%;
//     }

//     .delete-btn {
//       position: absolute;
//       cursor: pointer;
//     }
//   }

//   #form-file-upload {
//     height: 100%;
//     width: 100%;
//     text-align: center;
//     position: relative;
//     display: inline-block;
//   }

//   #input-file-upload {
//     display: none;
//   }

//   #label-file-upload {
//     height: 100%;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     border-width: 2px;
//     border-style: dashed;
//     border-color: #cbd5e1;
//     background-color: #f8fafc;
//   }

//   #label-file-upload.drag-active {
//     background-color: #e1e2e3;
//   }

//   #drag-file-element {
//     position: absolute;
//     width: 100%;
//     height: 100%;
//     border-radius: 1rem;
//     top: 0px;
//     right: 0px;
//     bottom: 0px;
//     left: 0px;
//   }
// }

// /* set banner image height equal to height of drag drop container */
// .event-banner-image {
//   height: $dnd-banner-height;
//   width: $dnd-banner-width;
// }

// .event-banner-image,
// .bg-image {
//   background-position: center;
//   background-repeat: no-repeat;
//   background-size: cover;
//   width: 100%;
// }

// .artist-thumbnail {
//   width: 170px;
//   height: 170px;
//   border-radius: 50%;
//   position: relative;
//   background-position: center;
//   background-size: cover;
//   background-repeat: no-repeat;
// }

// .artist-thumbnail.profile-image {
//   background-image: url('../../public/user-icon.png');
// }

// .btn-camera {
//   position: absolute;
//   border: 1px solid;
//   padding: 6px;
//   border-radius: 50%;
//   background: white;
//   bottom: 0;
//   left: 5px;
// }

// // banner slider

// .each-slide-effect > div {
//   display: flex;
//   align-items: center;
//   // justify-content: center;
//   background-size: cover;
//   height: 400px;
// }

// ul.indicators {
//   margin-top: -20px !important;
// }

// ul.indicators .each-slideshow-indicator:before {
//   background: white !important;
//   width: 10px !important;
//   height: 10px !important;
// }

// ul.indicators > li {
//   padding: 10px !important;
// }

// .twitter-logo {
//   padding: 1px 4px;
//   background: $primary;
//   border: 1px solid $primary;
//   border-radius: 50%;
// }

// .tabs-top-right-radius {
//   .nav-item {
//     &:nth-child(1) {
//       .nav-link {
//         position: relative;
//         z-index: 2;
//       }
//     }

//     &:nth-child(2) {
//       .nav-link {
//         margin-left: -25px;
//         position: relative;
//         z-index: 1;
//       }
//     }

//     .nav-link {
//       border-top-left-radius: 0;
//       border-bottom-left-radius: 0;
//       border-top-right-radius: 25px;
//       border-bottom-right-radius: 0;
//       padding: 7px 48px;
//       cursor: pointer;
//       width: 250px;

//       &:hover {
//         h5 {
//           font-weight: bold;
//         }
//       }
//     }
//   }
// }

// .border-bottom-dotted {
//   border-bottom-style: dotted !important;
// }
// .border-radius-10 {
//   border-radius: 10px;
// }

// .tab-gray {
//   --bs-bg-opacity: 1;
//   background-color: rgba(#e1e2e3, var(--bs-bg-opacity)) !important;
// }

// .performance-view {
//   color: #23242e !important;
//   border-radius: 1.5rem;
//   background: #f2f2f2;

//   &:hover {
//     background: white;
//   }
// }

// .custom-nav-item {
//   min-width: 200px;
//   border-radius: 20px; /* Adjust border-radius to change the pill's roundness */
//   margin-right: 10px; /* Optional: Adjust margin between pills */
// }

// .custom-nav-item .nav-link.active {
//   padding: 15px;
//   background-color: $primary !important;
// }

// .custom-nav-item .nav-link {
//   padding: 15px;
//   color: $white !important;
//   background-color: $lightGray !important;
// }

// .performance-view-link {
//   text-decoration: none !important;
//   color: $dark !important;
// }

// .pagination {
//   .page-item {
//     .page-link {
//       background: transparent;
//       border-color: var(--txt-color);
//       color: var(--txt-color);
//     }
//   }
// }

// .faq-accordion {
//   .accordion-item {
//     .accordion-header {
//       .accordion-button {
//         background: $white;
//         box-shadow: none;
//         color: $dark;

//         &::after {
//           content: '\2b';
//           background-image: none;
//           font-size: 2.5rem;
//           height: 53px;
//           width: 53px;
//           text-align: center;
//           position: absolute;
//           top: 0;
//           right: 0;
//           line-height: 3.5rem;
//           background-color: $dark;
//           color: $white;
//           transition: none;
//         }

//         &:not(.collapsed) {
//           &::after {
//             content: '-';
//             background-image: none;
//             background-color: $primary;
//           }
//         }
//       }
//     }
//   }
// }
