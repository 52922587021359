@import '../../../../scss/variables.scss';

.acceptTicketsSectionInfo {
  display: grid;
  grid-template-columns: $space-xl 1fr 1fr;
  gap: $space-sm;
  border-bottom: solid 2px #f5f6f7;
  padding: $space-xs $space-md;
  align-items: center;
  color: $headerBgColor;
  font-family: $font;
  font-size: $font-size-small;
  font-style: normal;
  font-weight: $font-weight-bold;
  line-height: 20px;
}
