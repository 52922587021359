@import '../../scss/variables.scss';
.address {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  .cityCountryContainer,
  .zipProvinceContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 16px;
  }
  @include largeBreakpoint {
    padding-left: 0;
    padding-right: 0;
    .cityCountryContainer,
    .zipProvinceContainer {
      flex-direction: row;
      gap: 14px;
    }
  }
}
