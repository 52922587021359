@import '../../scss/variables.scss';
h1 {
  font-family: $interFont;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: $textColorLight;
  &.registerTitle {
    color: $primaryColor;
    margin-bottom: 24px;
  }
}
.linksContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  gap: 11px;
  p {
    font-family: $interFont;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: $textColorLight;
    button {
      font-family: $interFont;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: $primaryColor;
      background-color: transparent;
      border: none;
      cursor: pointer;
    }
  }
}
