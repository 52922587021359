@import '../../scss/variables.scss';
.navItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .link {
    min-width: auto;

    padding: 20px 0;
    background-color: transparent;
    border: none;
    span {
      color: $textColorLight;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    &:hover {
      span {
        border-bottom: $hoverBorder;
      }
    }
    &.active {
      color: $primaryColor;
      font-weight: 700;
    }
  }
}
