@import '../../scss/variables.scss';

.contactInfoSavedModal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 11px;
  h2 {
    color: $textColorLight;
    font-family: $font;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  p {
    color: $textColorLight;
    font-family: $font;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
