@import '../../scss/variables.scss';

.faq {
  padding: 53px $mobilePadding;
  .faqContainer {
    h1 {
      font-family: Poppins;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      letter-spacing: 2.42px;
      text-transform: uppercase;
      color: $extraDarkBlue;
      & span {
        color: $primaryColor;
      }
    }
    .accordionContainer {
      margin-top: 60px;
      display: flex;
      flex-direction: column;
      gap: 32px;
      width: 100%;
    }
  }
  @include smallBreakpoint {
  }
  @include largeBreakpoint {
    .faqContainer {
      h1 {
        color: $extraDarkBlue;
        font-size: 42px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
        letter-spacing: 4.62px;
        text-transform: uppercase;
        & span {
          color: $primaryColor;
        }
      }
      .accordionContainer {
        margin-top: 60px;
        display: flex;
        flex-direction: column;
        gap: 32px;
        width: 100%;
      }
    }
  }
  @include xLargeBreakpoint {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    .faqContainer {
      max-width: $maxContentWidth;
      width: 100%;
    }
  }
}
