@import '../../scss/variables.scss';

.forgotPassword {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 75px 0;
  background: url('../../assets/banner.jpg') no-repeat center center;
  background-size: cover;
  min-height: calc(100vh - (#{$headerMobileHeight} + #{$mobileFooterHeight}));
  .forgotPasswordContainer {
    background-color: $headerBgColor;
    width: 425px;
    max-width: 100%;
    flex-shrink: 0;
    border-radius: 5px;
    padding: 31px 35px 96px;
    .head {
      h1 {
        font-family: $interFont;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        color: $textColorLight;
        &.registerTitle {
          color: $primaryColor;
          margin-bottom: 24px;
        }
      }
      .msgContainer {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: flex-start;
        gap: 11px;
        p {
          font-family: $interFont;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          color: $textColorLight;
          button {
            font-family: $interFont;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            color: $primaryColor;
            background-color: transparent;
            border: none;
            cursor: pointer;
          }
        }
      }
    }
    form {
      padding-top: 22px;
      padding-bottom: 27px;
      display: flex;
      gap: 23px;
      flex-direction: column;
      .formGroup {
        display: flex;
        gap: 30px;
        flex-direction: column;
        p {
          font-family: $interFont;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          color: $textColorLight;
        }
      }
    }
    .linkContainer {
      display: flex;
      flex-direction: column;

      .link {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
        text-decoration: none;
        color: $textColorLight;

        span {
          text-decoration: none;
          color: $textColorLight;
        }
        &:hover {
          span {
            border-bottom: 1px dotted $greyBoderColor;
          }
        }
      }
    }
  }
  @include largeBreakpoint {
    padding-left: 0;
    padding-right: 0;
    min-height: calc(100vh - (#{$headerHeight} + #{$desktopFooterHeight}));
  }
}
