@import '../../../../scss/variables.scss';

.sectionInfo {
  display: grid;
  grid-template-columns: 1fr 2fr 2fr 1fr;
  gap: $space-sm;
  border-bottom: solid 2px #f5f6f7;
  padding: $space-xs $space-md;
  align-items: center;
  color: $headerBgColor;
  font-family: $font;
  font-size: $font-size-small;
  font-style: normal;
  font-weight: $font-weight-bold;
  line-height: 20px;

  // justify-content: center;
  .status {
    display: grid;
    grid-template-columns: 6px 1fr;
    gap: $space-xs;
    align-items: center;
    div {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .ready {
      background-color: #219653;
    }
    .pending {
      background-color: #f1640c;
    }
    .transferred {
      background-color: #bbb4b5;
    }
  }
  button {
    background-color: transparent;
    border: none;
    color: $primaryColor;
    cursor: pointer;
    font-size: $font-size-small;
    font-family: $font;
    font-weight: $font-weight-normal;
    line-height: 20px; /* 166.667% */
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: none;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
    text-align: left;
  }
}
