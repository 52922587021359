@import '../../../../scss/variables.scss';

.bodyHeader {
  margin-top: $space-xs;
  padding: $space-md;
  display: grid;
  grid-template-columns: $space-xl 1fr 1fr;
  gap: $space-sm;
  background-color: $iceWhite;
  color: var(--Grey-Blue-60, #6b7a99);
  font-family: $font;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
}

.bodyContent {
  list-style: none;
}
