@import '../../scss/variables.scss';

.timer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: $space-xs;
  p {
    color: #f2994a;
    font-family: $font;
  }
  &.header {
    width: 100%;
    font-size: $font-size-small;
    p {
      span {
        display: none;
      }
    }
  }
}
