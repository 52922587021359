@import '../../../../scss/variables.scss';

.performanceCard {
  list-style: none;
  border: solid 2px #f5f6f7;
  border-radius: $border-radius-lg;
  box-shadow: 0px 2px 5px 0px rgba(38, 51, 77, 0.03);
  padding: $space-md 0;
  .performanceCardContainer {
    .performanceCardBody {
      gap: $space-md;
      width: 100%;
      border-top: solid 2px #f5f6f7;
      .bodyContent {
      }
      .performanceCardFooter {
        padding: $space-md;
        margin-top: $space-lg;
        display: flex;
      }

      &.hidden {
        display: none;
      }
    }
  }
}

.transferTicketsBtnTitle {
  display: flex;
  gap: $space-xs;
  align-items: center;
  justify-content: center;
  color: $headerBgColor;
  font-family: $font;
  font-size: $font-size-small;
  font-style: normal;
  font-weight: $font-weight-semi-bold;
  line-height: 20px;
  .icon {
    color: $textColorLight;
    background-color: $primaryColor;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &:hover {
    font-weight: $font-weight-bold;
  }
}
