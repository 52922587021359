@import '../../scss/variables.scss';

.typeCard {
  display: flex;
  align-items: center;
  gap: $space-md;
  padding: $space-md $space-lg;
  border-radius: $border-radius-lg;
  background: #f2f2f2;
  label {
    font-size: $font-size-small;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    color: $textColorDark;
    gap: $space-sm;
    width: auto;
    input {
      font-size: $font-size-small;
      width: 3rem;
      height: $space-lg;
      padding: $space-xs;
    }
  }
  p {
    color: $textColorSemiDark;
    font-size: $font-size-small;
    width: 800px;
    strong {
      text-transform: capitalize;
    }
  }
  .subTotal {
    font-size: $font-size-small;
  }
}
