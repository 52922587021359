@import '../../../../scss/variables.scss';

.transferTickets {
  width: 100%;
  display: flex;
  flex-direction: column;
  .closeBtnContainer {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
}
