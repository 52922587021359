@import '../../scss/variables.scss';

.sponsorsCardsContainer {
  height: 100%;
  padding: 0 $space-xl;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h3 {
    color: $primaryColor;
    font-family: $interFont;
    font-size: $font-size-base;
    font-style: italic;
    font-weight: $font-weight-bold;
    line-height: normal;
    text-align: center;
  }
  .sponsorsCards {
    margin-top: $space-md;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: $space-lg;
    overflow-x: scroll;
    scroll-behavior: smooth;
    scrollbar-width: none;
  }
  .sponsorsCards::-webkit-scrollbar {
    display: none;
  }
  @include mediumBreakpoint {
    h3 {
      font-size: $font-size-large;
    }
    .sponsorsCards {
      gap: $space-xl;
    }
  }

  @include largeBreakpoint {
    padding: 0;
    h3 {
      font-size: $font-size-h2;
    }
    .sponsorsCards {
      gap: $space-xxl;
    }
  }
}
