@import '../../scss/variables.scss';

.explanationGroup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  padding-top: 84px;
  min-width: 178px;
  max-width: 280px;

  img {
    height: 68px;
    width: auto;
  }
  h3 {
    padding-top: 20px;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .txtContainer {
    // height: 96px;
    padding-top: 28px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    p {
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      &.boldText {
        font-weight: 500;
      }
    }
  }
}
