@import '../../scss/variables.scss';

.about {
  padding: 42px $mobilePadding;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  .aboutContainer {
    width: 100%;
    .aboutTitle {
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-align: center;
      padding-bottom: 42px;
    }
    .subHeading {
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding-bottom: 7px;
    }
    .text {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: $textColorDark;
      padding-bottom: 14px;
    }
    .cta {
      padding-top: 82px;
      display: flex;
      flex-direction: column;
      max-width: 100%;
      .imgContainer {
        max-width: 100%;
        img {
          width: 100%;
          height: auto;
        }
      }
      .actionContainer {
        padding: 42px;
        h3 {
          font-size: 22px;
          font-weight: 500;
          line-height: 1.2;
        }
        .btnContainer {
          padding-top: 42px;
        }
      }
    }
  }

  @include mediumBreakpoint {
    .aboutContainer {
      .cta {
        display: grid;
        grid-template-columns: 2fr 1fr;
        // flex-direction: row;
        // align-items: center;
        // justify-content: center;
        gap: 30px;
        .actionContainer {
          padding: 0;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
        }
      }
    }
  }

  @include largeBreakpoint {
  }
  @include xLargeBreakpoint {
    .aboutContainer {
      max-width: $maxContentWidth;
    }
  }
}
