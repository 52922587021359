@import '../../scss/variables.scss';

.videoSection {
  padding: 0 $mobilePadding;
  margin-top: $space-xl;
  @include smallBreakpoint {
  }
  @include mediumBreakpoint {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .videoContainer {
      width: 100%;
      max-width: 645px;
    }
  }
  @include largeBreakpoint {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .videoContainer {
      width: 100%;
      max-width: $maxContentWidth;
    }
  }
}
