@import '../../scss/variables.scss';

.eventPerformanceHeader {
  // z-index: 1;
  border-radius: 0px 0px 3rem 3rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #f3fbfd;
  .posterContainer {
    width: 100%;
    position: relative;
    img {
      width: 100%;
    }
    .ticketBtnContainer {
      position: absolute;
      left: 0;
      bottom: $space-lg;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .btnPos {
        display: flex;
        width: calc(100% - 2 * #{$mobilePadding});
        align-items: flex-end;
        justify-content: flex-end;
        margin-right: $mobilePadding;
      }
    }
  }
  .headerCard {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: $space-md $mobilePadding $space-lg;
    .infoContainer {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: $space-lg;
      margin-bottom: $space-md;
      .group1 {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: $space-md;
        h2 {
          width: 100%;
          color: $textColorSemiDark;
          font-family: $font;
          font-size: $font-size-h2;
          font-style: normal;
          font-weight: $font-weight-extra-bold;
          line-height: normal;
          text-align: left;
        }
        .date {
          width: 100%;
          font-size: $font-size-base;
          color: $textColorSemiDark;
          font-family: $font;
          font-style: normal;
          font-weight: $font-weight-normal;
          line-height: normal;
          display: flex;
          align-items: center;
          gap: $space-sm;
          span {
            color: $primaryColor;
            font-size: $font-size-large;
          }
        }
        .venue {
          width: 100%;
          display: flex;
          gap: 0.5rem;
          align-items: center;
          color: $textColorSemiDark;
          font-family: $interFont;
          font-size: $font-size-base;
          font-style: normal;
          font-weight: $font-weight-normal;
          line-height: normal;
          background-color: transparent;
          border: none;
          text-decoration: none;
          span {
            font-size: $font-size-large;
            color: $primaryColor;
            font-weight: $font-weight-extra-bold;
          }
        }
        .promoterBtn {
          width: 100%;
          display: flex;
          gap: 0.5rem;
          align-items: center;
          color: $textColorSemiDark;
          font-family: $interFont;
          font-size: $font-size-base;
          font-style: normal;
          font-weight: $font-weight-normal;
          line-height: normal;
          background-color: transparent;
          border: none;
          text-decoration: none;

          &.disabled {
            cursor: default;
            span {
              text-decoration: none;
            }
          }
          span {
            font-weight: $font-weight-extra-bold;
            text-decoration: underline;
          }
        }
      }
      .group2 {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: $space-lg;
        .btnsContainer {
          display: grid;
          grid-template-columns: 1fr 1fr;
          width: 100%;
          align-items: flex-start;
          // gap: $space-md;
          .btn {
            display: flex;
            gap: 0.5rem;
            align-items: center;
            color: $textColorSemiDark;
            font-family: $font;
            font-size: $font-size-base;
            font-style: normal;
            font-weight: $font-weight-semi-bold;
            line-height: normal;
            background-color: transparent;
            border: none;
            span {
              font-size: $font-size-large;
              color: $primaryColor;
            }
          }
        }
      }
    }
  }
  @include smallBreakpoint {
    padding-left: 0;
    padding-right: 0;
    .posterContainer {
      .ticketBtnContainer {
        .btnPos {
          margin-right: $tabletPadding;
        }
      }
    }
    .headerCard {
      padding: $space-md $tabletPadding $space-lg;
    }
  }

  @include mediumBreakpoint {
    .headerCard {
      width: 100%;
      .infoContainer {
        display: grid;
        grid-template-columns: 1fr 310px;
        width: 100%;

        .group1 {
          gap: 0;
          align-items: flex-start;
          h2 {
            font-size: $font-size-h1;
          }
          .date {
            font-size: $font-size-large;
            gap: 0;
            span {
              margin-right: $space-md;
              font-size: $font-size-h1;
              width: $font-size-h1;
            }
          }
          .promoterBtn {
            font-size: $font-size-base;
            margin-left: calc(#{$font-size-h1} + #{$space-md});
          }
        }
        .group2 {
          // width: 310px;
          gap: $space-md;
          align-items: flex-start;
          .btnsContainer {
            width: 100%;
            gap: $space-sm;
            .btn {
              min-width: 150px;
              flex-direction: column;
              align-items: flex-start;
              gap: $space-xs;
              font-size: $font-size-base;
              span {
                height: $font-size-large;
              }
            }
          }
        }
      }
    }
  }

  @include largeBreakpoint {
    padding-left: 0;
    padding-right: 0;
    padding-left: 0;
    padding-right: 0;
    .posterContainer {
      .ticketBtnContainer {
        .btnPos {
          margin-right: 120px;
          margin-bottom: 90px;
          max-width: $maxContentWidth;
        }
      }
    }
    .headerCard {
      .infoContainer {
        max-width: $maxContentWidth;
        .group1 {
          h2 {
            font-size: 38px;
          }
          .venue {
            font-size: 18px;
            font-weight: $font-weight-semi-bold;
            margin-bottom: $space-md;
            span {
              font-size: $font-size-h1;
              margin-right: 14px;
            }
          }
        }
      }
    }
  }
}

// Style for button content
.btnTitleContainer {
  p {
    font-family: $font;
    font-size: $font-size-base;
    font-style: normal;
    font-weight: $font-weight-bold;
    line-height: normal;
  }
  span {
    font-family: $font;
    font-size: $font-size-small;
    font-style: normal;
    font-weight: $font-weight-normal;
    line-height: normal;
  }

  @include largeBreakpoint {
    padding-left: 0;
    padding-right: 0;
    p {
      font-size: $font-size-large;
    }
    span {
      font-size: $font-size-base;
    }
  }
}
