// @import '../../scss/variables.scss';
@import '../../../scss/variables.scss';

.myOrders {
  max-width: 100%;
  padding-top: 35px;
  padding-bottom: 82px;
  .myOrdersContainer {
    max-width: 100%;
    h2 {
      color: #2a2c3a;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .searchContainer {
      display: grid;
      grid-template-columns: repeat(2, minmax(130px, 1fr));
      grid-template-rows: 43px;
      gap: 10px;
      width: 100%;
      margin-top: 12px;
      input {
        padding: 12.5px 12px;
        border: solid 1px $greyBoderColor;
        flex-shrink: 0;
        font-family: $interFont;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        border-radius: 5px;
        ::placeholder {
          color: #868e96;
        }
      }
    }
    .eventTypes {
      width: 100%;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 60px;
      margin-top: 19px;
      background-color: $lightBackground;
      border-radius: 0px 40px 0px 0px;
      &.pastSelected {
        background-color: $primaryColor;
      }
      button {
        width: 50%;
        display: inline-block;
        word-break: break-all;
        word-wrap: break-word;
        white-space: normal;
        padding: 5px;
        min-height: 60px;
        height: 100%;
        border-radius: 0px 40px 0px 0px;
        border: none;
        background: $lightBackground;
        &.active {
          background: $primaryColor;
        }
        span {
          display: inline-block;
          word-break: break-all;
          word-wrap: break-word;
          white-space: normal;
          width: 100%;
          color: $textColorLight;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
    }
  }
  @include largeBreakpoint {
    padding: 0;
    padding-top: 95px;
    padding-bottom: 82px;
  }
}
