/* @import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300;400;700&display=swap'); */
@import 'react-quill/dist/quill.snow.css';

/* :root {
  font-size: 14px;
  --shadow: rgb(0, 0, 0, 0.1); 
}

.theme-dark {
  --shadow: rgb(0, 0, 0, 0.2);
} */

body {
  margin: 0;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  min-height: 100vh;
}

button,
a {
  cursor: pointer;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* ::-webkit-scrollbar {
  width: 8px; 
} */

/* body ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px;
  height: 8px;
} */

/* body ::-webkit-scrollbar-thumb {
  background: #b5b5b5;
} */

/* body ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #b5b5b5;
} */

/* adjust scrollbar width for small and mobile screens */
@media screen and (max-width: 768px) {
  /* ::-webkit-scrollbar {
    width: 4px; 
  }
  
  body ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
    height: 4px;
  } */
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-10 {
  font-size: 10px !important;
}

.gap-4 {
  gap: 1.5rem;
}

.rounded-pill {
  border-radius: 40px !important;
}

.input-group-search {
  position: relative;
}

.input-group-search .form-control {
  padding-right: 30px;
}

.input-group-search svg.fa-magnifying-glass {
  position: absolute;
  right: 10px;
  top: 10px;
}

.bg-white-opaque {
  background-color: #ffffffb0 !important;
}

.bg-green-grey {
  background-color: #b9c8c5 !important;
}

.event-alert {
  background-color: #353a3b;
  color: #ffffff;
  font-size: 0.875rem;
  text-align: center;
} */

/* .avatar {
  width: 35px;
  height: 35px;
  background-image: url('/public/profile_pic.jpg');
  border-radius: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  margin-left: 1rem;
  margin-right: 1rem;
} */

/* .vip-pass {
  font-size: 8px;
  padding: 1px 3px;
  border-radius: 4px;
  position: absolute;
  top: -5px;
  right: -10px;
  font-weight: bold;
}

video {
  object-fit: cover;
}

.shadow-left {
  box-shadow: 12px 0px 20px var(--shadow);
  position: relative;
}

.radius-20 {
  border-radius: 20px !important;
}

.streaming-banner {
  background: url(../public/streaming-banner.jpg);
  height: 400px;
  background-repeat: no-repeat;
  background-size: cover;
}

.hr-text::after,
.hr-text::before {
  content: '';
  display: block;
  height: 0.05rem;
  background-color: #353a3b;
  position: absolute;
  top: 50%;
  width: calc(100% - 30px);
  transform: translateX(calc(-100% - 10px));
}

.hr-text::after {
  transform: translateX(calc(100% + 40px)) !important;
}

.hr-text-wrapper {
  text-align: center;
  overflow: hidden;
}

.hr-text {
  position: relative;
  display: inline-block;
}

.page-not-found {
  height: calc(100vh - 75px);
  width: 75%;
  background-position: top center;
  margin: 0 auto;
  background-image: url(../public/page-not-found.png);
} */

/* @media (max-width: 991px) {
  .shadow-lg-show {
    box-shadow: none !important;
  }
} */

/* css for carousel */

/* @-webkit-keyframes carousel {
  0% {
    transform: translateX(800%);
  }

  to {
    transform: translateX(-100%);
  }
}

@keyframes carousel {
  0% {
    transform: translateX(800%);
  }

  to {
    transform: translateX(-100%);
  }
}

@-webkit-keyframes carousel-reverse {
  0% {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(800%);
  }
}

@keyframes carousel-reverse {
  0% {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(800%);
  }
}

.artists-carousel {
  position: relative;
  display: flex;
  height: 5.5rem;
  flex-wrap: nowrap;
  overflow: hidden;
  list-style-type: none;
} */

/* @media (min-width: 768px) {
  .artists-carousel {
    height: 11.25rem;
  }
}

.artists-carousel li {
  -webkit-animation: carousel 120s linear infinite;
  animation: carousel 120s linear infinite;
  position: absolute;
  height: 5.5rem;
  width: 10rem;
  flex-shrink: 0;
  padding-left: 1.5rem;
}

.artists-carousel li img {
  max-height: 100%;
  width: 100%;
}

.artists-carousel li h3,
.artists-carousel li .title {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 9;
  transform: translate(-50%, -50%);
  margin: 0;
  font-weight: bold;
}

@media (min-width: 768px) {
  .artists-carousel li {
    height: 11.25rem;
    width: 20rem;
  }
}

.artists-carousel li:first-child {
  -webkit-animation-delay: -13.3333333333s;
  animation-delay: -13.3333333333s;
}

.artists-carousel li:nth-child(2) {
  -webkit-animation-delay: -26.6666666667s;
  animation-delay: -26.6666666667s;
}

.artists-carousel li:nth-child(3) {
  -webkit-animation-delay: -40s;
  animation-delay: -40s;
}

.artists-carousel li:nth-child(4) {
  -webkit-animation-delay: -53.3333333333s;
  animation-delay: -53.3333333333s;
}

.artists-carousel li:nth-child(5) {
  -webkit-animation-delay: -66.6666666667s;
  animation-delay: -66.6666666667s;
}

.artists-carousel li:nth-child(6) {
  -webkit-animation-delay: -80s;
  animation-delay: -80s;
}

.artists-carousel li:nth-child(7) {
  -webkit-animation-delay: -93.3333333333s;
  animation-delay: -93.3333333333s;
}

.artists-carousel li:nth-child(8) {
  -webkit-animation-delay: -106.6666666667s;
  animation-delay: -106.6666666667s;
}

.artists-carousel li:nth-child(9) {
  -webkit-animation-delay: -120s;
  animation-delay: -120s;
}

.artists-carousel--reverse li {
  -webkit-animation-name: carousel-reverse;
  animation-name: carousel-reverse;
}

.artist-image-height {
  height: 15rem;
}

.dropdown-toggle.no-caret::after {
  display: none;
}

.ck-editor__editable {
  min-height: 200px;
}

.border-left-0 {
  border-left: none !important;
}

.border-left-dashed-5 {
  border-left: 4px dashed;
}

.custom-calender-input {
  position: relative;
}

.custom-calender-input > .fa-calendar-days {
  position: absolute;
  z-index: 9;
  top: 10px;
  left: 5px;
}

.custom-calender-input .form-control {
  padding-left: 1.5rem;
}

.modal-scroll {
  overflow: auto;
  max-height: 485px;
}

@media screen and (max-width: 768px) {
  .modal-scroll {
    overflow-x: hidden;
    max-height: 250px;
  }

  .mobile-filter-sidepanel {
    position: fixed;
    top: 0;
    left: -500px;
    background: white;
    color: black;
    z-index: 9999;
    width: 70vw !important;
    height: 100vh;
    overflow: auto;
    transition: all 0.7s;
  }

  .mobile-filter-sidepanel.show {
    left: 0;
  }
}

.event-banner-thumbnail {
  width: 100px;
  height: 80px;
}

.banner-wrapper {
  position: relative;
}

.banner-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #0000009e;
}

.event-card {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.hoverable-card .event-card:hover {
  transform: scale(1.1, 1.15);
  position: relative;
  z-index: 9;
} */

/* .hoverable-card .event-card:hover .hoverable-text-visible {
  opacity: 1;
}

.hoverable-card .event-card:hover .hoverable-text-hidden {
  opacity: 0;
}

.hoverable-text-hidden,
.hoverable-text-visible,
.event-card {
  transition-timing-function: ease-out;
  transition: 0.5s;
}

.hoverable-text-visible {
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
} */

/* .event-card-text-ellipses {
  max-height: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.ticket {
  border: 1px solid rgba(var(--bg-color));
  border-radius: 25px;
  position: relative;
  color: var(--list-ticket-card-hover);
  background-color: var(--list-ticket-card-title);
  min-width: 400px;
}

.ticket::before,
.ticket::after {
  content: '';
  width: 100px;
  height: 100px;
  background: var(--bg-color);
  position: absolute;
  border-radius: 50%;
  top: calc(50% - 50px);
}

.ticket::after {
  right: -50px;
}

.ticket::before {
  left: -50px;
} */

/* @media screen and (max-width: 768px) {
  .ticket::before,
  .ticket::after {
    content: '';
    width: 50px;
    height: 50px;
    background: var(--bg-color);
    position: absolute;
    border-radius: 50%;
    top: calc(50% - 25px);
  }

  .ticket::after {
    right: -25px;
  }

  .ticket::before {
    left: -25px;
  }
}

@media (max-width: 992px) {
  .checkout-modal {
    max-width: 100vw !important;
    margin: 0 !important;
  }
} */

/* stepper css */
/* .timeline-steps {
  display: flex;
  justify-content: center;
  flex-wrap: row;
}

.timeline-steps .timeline-step {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
}

.timeline-steps .timeline-step:not(:last-child):after {
  content: '';
  display: block;
  border-top: 8px solid #f0f0f0;
  width: 5.5rem;
  position: absolute;
  left: 70px;
  top: 0.5rem;
}

.timeline-steps .timeline-step.completed:not(:last-child):after {
  border-color: #79a83b;
}

.timeline-steps .timeline-content {
  width: 95px;
  text-align: center;
}

.timeline-steps .timeline-content .inner-circle {
  border-radius: 1.5rem;
  height: 27.001px;
  width: 27.001px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #79a83b;
} */

/* Outer circle */
/* .timeline-steps .timeline-content .inner-circle:before {
  content: '';
  background-color: #f0f0f0;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
  display: inline-block;
  height: 40px;
  width: 40px;
  min-width: 40px;
  border-radius: 6.25rem;
  opacity: 0.5;
} */

/* Tick Sign */
/* .completed .step-number {
  position: absolute;
  color: white;
  background-color: #79a83b;
}

.completed .step-number span {
  position: relative;
}

.completed .step-number span::before {
  content: '\2713';
  width: 22px;
  height: 22px;
  background: #79a83b;
  position: absolute;
  border-radius: 50%;
  left: -4px;
  top: 2px;
} */

/* Small white circle */
/* .current-step .step-number {
  border-radius: 1.5rem;
  height: 15px;
  width: 15px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: white;
} */

/* numbers style */
/* .step-number {
  border-radius: 1.5rem;
  height: 27.001px;
  width: 27.001px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: #bfbfbf;
  color: #fff;
  font-family: Roboto;
  font-size: 19.854px;
  font-style: normal;
  font-weight: 700;
}

.form-check-input:checked {
  background-color: #79a83b !important;
  border-color: #79a83b !important;
  box-shadow: none;
}

.form-check-input:focus {
  box-shadow: none;
}

.border-hover-right {
  border-left: 4px solid transparent;
}

.border-hover-right:hover {
  border-color: #79a83b;
}

.demo-page-section2 {
  background-image: url(../public/demopage-bg-img.png);
  background-size: cover;
  background-repeat: no-repeat;
  height: 800px;
}

.laptop-background {
  background-size: contain;
  background-image: url(../public/laptop.png);
  background-repeat: no-repeat;
  width: 500px;
  height: 330px;
  position: relative;
}

.laptop-background > img {
  position: absolute;
  left: 68px;
  top: 24px;
  width: 372px;
  height: 238px;
} */
