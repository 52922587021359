@import "../../scss/variables.scss";

.customButton {
  display: inline-flex;
  padding: 15px 40px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: $border-radius-sm;
  border: none;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  &.blue {
    color: $textColorLight;
    background: $primaryColor;
  }
  &.white {
    background-color: $bgColor;
    color: $textColorDark;
    &.smallText {
      color: $textColorSemiDark;
    }
  }
  &.black {
    background-color: $headerBgColor;
    color: $textColorLight;
  }
  &.outlineWhite {
    background-color: $bgColor;
    color: $textColorDark;
    border: 1px solid $textColorDark;
    width: 100%;
    &:hover {
      border: 2px solid $textColorDark;
    }
  }
  &.smallText {
    text-align: center;
    font-family: $font;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 100%;
    padding: 7px 8px;
    @include largeBreakpoint {
      padding-left: 0;
      padding-right: 0;
      font-size: 18px;
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }

  &.aboutUs {
    width: 175px;
    padding: 15px 0;
  }
  &.eventFilter {
    font-size: 12px;
    padding: 10px 20px;
    width: 68px;
  }
  &:hover {
    box-shadow: 0px 4px 4px 0px rgba(255, 255, 255, 0.2);
    font-weight: 700;
  }
  &.pill {
    border-radius: 40px;
    &.home {
      width: 190px;
      padding: 15px 0;
    }
    &:hover {
      font-weight: 700;
      box-shadow: 4px 4px 4px 0px rgba(237, 226, 226, 0.25);
    }
  }
  &.eventViewTickets {
    width: 325px;
    padding: 6px 34px;
    // border-radius: 40px;
    &:hover {
      padding: 6px 34px;
    }
    @media screen and (max-width: 429px) {
      width: 250px;
      padding: 6px 25px;
    }
    @include largeBreakpoint {
      padding: 6px 25px;
      width: 230px;
      // height: 95px;
      // border-radius: 20px;
      &:hover {
        padding: 6px 25px;
      }
    }
  }

  &.eventBuyTickets {
    width: 325px;
    padding: 11px 34px;
    &:hover {
      padding: 11px 34px;
    }
    @media screen and (max-width: 429px) {
      width: 250px;
      padding: 11px 25px;
    }
    @include largeBreakpoint {
      padding: 20px 25px;
      width: 190px;
      height: 95px;
      border-radius: 20px;
      &:hover {
        padding: 20px 25px;
      }
    }
  }
  &.vip {
    width: 250px;
    padding: 11px 0;
  }
  &.imgUploaderCancel {
    background-color: $danger;
    color: $textColorLight;
    border: 1px solid $danger;
    width: 120px;
    padding: 10px 0;
    font-size: $font-size-base;
    &:hover {
      font-weight: $font-weight-bold;
      // border: 1px solid $iceWhite;
    }
  }
  &.crop {
    width: 150px;
    font-size: $font-size-base;
    padding: $space-md $space-sm;
  }

  &.white-outline {
    background-color: $bgColor;
    border: solid 1px $textColorDark;
    color: $textColorDark;
    width: 200px;
  }
  &.checkout {
    width: 200px;
    padding: 16px 40px;
  }
  &.ticketPerformanceCard {
    display: flex;
    width: 150px;
    height: 40px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 20px;
    border: 2px solid $iceWhite;
    background: $primaryColor;

    /* Shadows/Gray Blue/3%/5b */
    box-shadow: 0px 2px 5px 0px rgba(38, 51, 77, 0.03);
    text-align: center;
    font-feature-settings: "liga" off, "clig" off;
    font-family: $font;
    font-size: $font-size-small;
    font-style: normal;
    font-weight: $font-weight-semi-bold;
    line-height: 20px;
    &:hover {
      font-weight: $font-weight-bold;
    }
  }
  &.ticketPerformanceCardWhite {
    display: flex;
    width: 150px;
    height: 40px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 20px;
    border: 2px solid $iceWhite;
    background: $bgColor;
    color: $headerBgColor;
    /* Shadows/Gray Blue/3%/5b */
    box-shadow: 0px 2px 5px 0px rgba(38, 51, 77, 0.03);
    text-align: center;
    font-feature-settings: "liga" off, "clig" off;
    font-family: $font;
    font-size: $font-size-small;
    font-style: normal;
    font-weight: $font-weight-semi-bold;
    line-height: 20px;
    &:hover {
      font-weight: $font-weight-bold;
    }
  }
  &.orderCard {
    font-size: $font-size-small;
    background-color: transparent;
    border: none;
    color: $textColorLight;
    font-weight: $font-weight-semi-bold;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0;
    font-weight: $font-weight-light;
    border-radius: 0;
    &:hover {
      box-shadow: none;
      border-bottom: $hoverBorder;
    }
  }
}
