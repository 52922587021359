@import '../../scss/variables.scss';

.performance {
  .performanceContainer {
    margin-bottom: $space-xl;
    .eventDirection {
      width: 100%;
      padding: 0 $mobilePadding;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: $textColorSemiDark;
      text-decoration: none;
      .eventDirectionContainer {
        padding: $space-md 0;
        width: 100%;
        display: flex;
        font-size: $font-size-large;
        justify-content: flex-start;
        align-items: center;
        gap: $space-sm;
        span {
          font-size: $font-size-large;
          font-style: normal;
          font-weight: $font-weight-extra-bold;
          line-height: normal;
        }
      }
    }
    .performances {
      margin-top: $space-xl;
      padding: 0 $mobilePadding;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .performancesContainer {
        .performancesCards {
          h3 {
            font-size: $font-size-large;
          }
          .performancesCardsContainer {
            width: 100%;
            margin-top: $space-lg;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: $space-md;
          }
        }
      }
    }

    .sponsors {
      margin-top: $space-lg;
      height: 185px;
      width: 100%;
      background: url('../../assets/sponsor-top.webp'),
        url('../../assets/sponsor-bottom.webp');
      background-repeat: no-repeat, no-repeat;
      background-size: 122px auto, 103px auto;
      background-position: top 0px right 10px, bottom 0 left 0;
      background-color: $headerBgColor;
    }
  }
  @include smallBreakpoint {
    padding-left: 0;
    padding-right: 0;
    .performanceContainer {
      .eventDirection,
      .performances {
        padding-left: $tabletPadding;
        padding-right: $tabletPadding;
      }
    }
  }

  @include mediumBreakpoint {
    .performanceContainer {
      .performances {
        .performancesContainer {
          .performancesCards {
            h3 {
              font-size: $font-size-large;
            }
            .performancesCardsContainer {
              gap: $space-lg;
            }
          }
        }
      }
      .sponsors {
        height: 240px;
        background-size: 240px auto, 205px auto;
        .sponsorsContainer {
          h3 {
            font-size: $font-size-large;
          }
        }
      }
    }
  }
  @include largeBreakpoint {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    .performanceContainer {
      width: 100%;
      margin-bottom: 200px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      //   .social,
      .eventDirection,
      .performances {
        padding-left: $desktopPadding;
        padding-right: $desktopPadding;
      }
      .eventDirection {
        .eventDirectionContainer {
          max-width: $maxContentWidth;
          padding: $space-xl 0;
          font-size: $font-size-h1;
          gap: $space-lg;
          span {
            font-size: $font-size-h1;
          }
        }
      }
      .performances {
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-top: $space-xxl;
        .performancesContainer {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          width: 100%;
          max-width: $maxContentWidth;
          .performancesCards {
            width: 100%;
            h3 {
              width: 100%;
              font-size: $font-size-h1;
              text-align: left;
              font-weight: $font-weight-extra-bold;
            }
            .performancesCardsContainer {
              width: 100%;
              gap: $space-xl;
            }
          }
        }
      }
      .sponsors {
        height: 240px;
        padding-left: $desktopPadding;
        padding-right: $desktopPadding;
        background-size: 407px auto, 347px auto;
        margin-top: 170px;
      }
    }
  }
}
