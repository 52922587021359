@import '../../scss/variables.scss';

.imageThumbnail {
  background-color: $headerBgColor;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  color: $iceWhite;
  border: none;
  cursor: pointer;
  padding: 25px;
  font-size: 65px;
  img {
    height: auto;
    width: 70px;
    // border-radius: 50%;
    object-fit: cover;
  }
  &:hover {
    border: 5px solid $primaryColor;
    padding: 21px;
    font-size: 62px;
  }
}
