@import '../../scss/variables.scss';

.imageUploader {
  //   width: 350px;

  .imageUploader {
    .imageUploaderContainer {
      border: 2px dashed #ddd;
      padding: 20px;
      border-radius: $border-radius-lg;
      cursor: pointer;
      width: 100%;
      height: 150px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: $font-size-base;
      &:hover {
        border: 4px dashed $greyBoderColor;
        font-weight: $font-weight-bold;
      }
    }
    .btnContainer {
      margin-top: $space-md;
    }
  }
}
