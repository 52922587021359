@import '../../scss/variables.scss';
.modalContent {
  width: 100%;
  h2 {
    color: $textColorLight;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: -0.44px;
    margin: 0;
  }
  h6 {
    color: $textColorLight;
    font-family: $interFont;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
  }
  .btnsContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 40px 0;
    align-items: flex-start;
  }
  p {
    color: $textColorLight;
    font-family: $interFont;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    align-items: center;
    .loginLink {
      color: $primaryColor;
      text-decoration: none;
      font-family: $font;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
      letter-spacing: -0.36px;
      padding-left: 29px;
      border: none;
      background-color: transparent;
      cursor: pointer;
      &:hover {
        font-weight: 700;
        text-decoration: underline;
      }
    }
  }
}

.modalButton {
  display: inline-flex;
  padding: 15px 40px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border-radius: 40px;
  background: $primaryColor;
  border: none;
  color: $textColorLight;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  &:hover {
    font-weight: 700;
    box-shadow: 4px 4px 4px 0px rgba(237, 226, 226, 0.25);
  }
}
