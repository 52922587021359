@import '../../scss/variables.scss';

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 $mobileRightHeaderPadding 0 $mobilePadding;
  background-color: $headerBgColor;
  position: relative;
  height: $headerMobileHeight;
  z-index: 10;
  .navbarContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    .mobileShow {
      height: $headerMobileHeight;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .logo {
        width: 300px;
      }
      .mobileShowBtns {
        display: flex;
        gap: $space-md;
        .navbarToggler {
          background-color: transparent;
          border: 1px solid rgba(255, 255, 255, 0.3);
          color: $textColorLight;
          border-radius: 6px;
          width: 48px;
          height: 48px;
          font-size: 26px;
          flex-shrink: 0;
          .close {
            display: inline-block;
          }
          .hamburger {
            display: none;
          }
          &.collapsed {
            font-size: 26px;
            .close {
              display: none;
            }
            .hamburger {
              display: inline-block;
            }
          }
        }
        .cartBtnContainer {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      }
    }
    .navLinksContainer {
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      z-index: 10;
      background-color: $headerBgColor;
      &.collapsed {
        display: none;
      }
      padding-bottom: 20px;
      .links {
        .cartBtnContainer {
          display: none;
        }
      }
    }
  }

  @include smallBreakpoint {
  }

  @include largeBreakpoint {
    .navbarContainer {
      flex-direction: row;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      .mobileShow {
        height: $headerHeight;
        .mobileShowBtns {
          display: none;
          // .navbarToggler {
          // }
        }
      }
      .navLinksContainer,
      .navLinksContainer.collapsed {
        display: flex;
        position: static;
        align-items: center;
        justify-content: center;
        padding-bottom: 0;
        .links {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 50px;
          .cartBtnContainer {
            display: block;
          }
        }
      }
    }
  }

  @include xLargeBreakpoint {
    .navbarContainer {
      max-width: $maxContentWidth;
    }
  }
}

// Cart button

.cartBtn {
  position: relative;
  background-color: transparent;
  border: none;
  color: $primaryColor;
  font-size: 24px;
  cursor: pointer;

  .cartCount {
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: $bgColor;
    color: $primaryColor;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: $font-size-small;
  }
}
