@import '../../scss/variables.scss';
.logoContainer {
  .logo {
    width: 198px;
    img {
      width: 100%;
      height: auto;
    }
  }
  .smallLogo {
    display: none;
  }
  @include smallBreakpoint {
    padding: 0;
    .logo {
      width: 300px;
    }
  }

  @include largeBreakpoint {
    padding: 0;
    .logo {
      display: none;
    }
    .smallLogo {
      display: inline-block;
      width: 95.2px;
    }
  }

  @include xLargeBreakpoint {
    padding: 0;
    .logo {
      display: inline-block;
    }
    .smallLogo {
      display: none;
    }
  }
}
