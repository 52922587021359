@import '../../../../scss/variables.scss';

.successfullCreatedTransferRequest {
  width: 100%;
  .closeContainer {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
  .container {
  }
}
