@import '../../scss/variables.scss';

.closeAccountPopupContent {
  background-color: $bgColor;
  width: 100%;
  max-width: 100%;
  .title {
    color: $textColorDark;
    font-family: $font;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .formGroup {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    margin-top: 35px;
  }

  @include largeBreakpoint {
    .title {
      font-size: 28px;
    }
    .formGroup {
      display: grid;
      grid-template-columns: 1.1fr 1fr;
      gap: 24px;
      width: 100%;
    }
  }
}
