@import '../../scss/variables.scss';

.locationSearch {
  position: relative;
  width: 200px;
  height: 2.25rem;
  input {
    width: 100%;
    padding: $space-sm;
    border-radius: $border-radius-lg;
    border: 1px solid $headerBgColor;
    background: transparent;
    color: $headerBgColor;
    font-family: $interFont;
    font-size: $font-size-small;
    font-style: normal;
    font-weight: $font-weight-normal;
    line-height: normal;
  }
  .suggestions {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: $bgColor;
    border: 1px solid $lightBorderGrey;
    border-radius: $border-radius-lg;
    list-style: none;
    padding: 0;
    margin: 0;
    height: 300px;
    overflow-y: scroll;
    z-index: 1000;
    font-family: $interFont;
    .suggestion {
      padding: $space-sm;
      cursor: pointer;
      font-family: $interFont;
      font-size: $font-size-small;
      font-style: normal;
      font-weight: $font-weight-normal;
      line-height: normal;
    }
  }
}
