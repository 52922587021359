@import "../../scss/variables.scss";

.generalTimer {
  display: flex;
  align-items: center;
  justify-content: center;

  .time {
    color: #f2994a;
    font-family: $font;
    font-size: $font-size-small;
  }
}
