@import "../../../scss/variables.scss";

.orderCard {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 550px;
  border: solid 1px $greyTextColor;
  border-radius: $border-radius-md;
  color: $textColorLight;
  font-family: $font;
  padding: $space-md 0;
  // background-color: $primaryColor;
  background-color: $modalBgColor;
  .orderCardHeader {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    background-color: $modalBgColor;
    padding: 0 $space-md;
    border-radius: $border-radius-sm $border-radius-sm 0 0;
    color: $textColorLight;
    .orderDate,
    .orderTotal,
    .orderNumber {
      display: flex;
      flex-direction: column;
      .description {
        font-size: $font-size-small;
        text-transform: uppercase;
        color: $iceWhite;
        font-family: $interFont;
      }
    }
  }
  .event {
    padding: $space-sm $space-md;
    font-size: $font-size-small;
    color: $iceWhite;
    .eventLink {
      font-size: $font-size-base;
      color: $textColorLight;
      text-decoration: none;
      &:hover {
        border-bottom: $hoverBorder;
      }
    }
  }
  .btnsContainer {
    display: inline flex;
    gap: $space-sm;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
    font-size: $font-size-small;
    padding: $space-sm $space-md;
    height: $space-lg;
    .link {
      font-size: $font-size-small;
      // color: $textColorLight;
      color: $primaryColor;
      text-decoration: none;
      &:hover {
        border-bottom: $hoverBorder;
      }
    }
  }
  .showMoreContainer {
    margin-top: $space-md;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    .showMore {
      // color: $textColorLight;
      color: $primaryColor;
      font-family: $font;
      font-size: $font-size-base;
      background-color: transparent;
      border: none;
    }
  }
  .ticketsInfoContainer {
    list-style: none;
    padding: $space-md;
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: $space-md;
  }
}
