@import '../../scss/variables.scss';

.cart {
  .cartContainer {
    margin: $space-lg 0;
    padding: 0 $mobilePadding;
    h1 {
      color: red;
    }
    .cartSummaryContainer {
      display: flex;
      justify-content: space-between;
      h3 {
        font-size: $font-size-base;
      }
    }
  }
}
