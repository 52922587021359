@import '../../scss/variables.scss';

.changeCredentialsPopupContent {
  background-color: $bgColor;
  max-width: 100%;
  .title {
    color: $textColorDark;
    font-family: $interFont;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 51px;
  }
  .formGroup {
    display: flex;
    flex-direction: column;
    gap: 25.5px;
    margin-bottom: 46.5px;
    width: 100%;
  }

  @include largeBreakpoint {
    .title {
      font-size: 28px;
    }
  }
}
