@import '../../scss/variables.scss';

.sponsorCard {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  gap: $space-sm;
  align-items: center;
  width: auto;
  text-decoration: none;
  &.sponsorCardNoLink {
    cursor: default;
    &:hover {
      border-bottom: none;
      p {
        font-weight: $font-weight-normal;
      }
    }
  }
  img {
    height: $space-xxl;
  }
  p {
    color: $textColorLight;
    font-family: $interFont;
    font-size: $font-size-small;
    font-style: normal;
    font-weight: $font-weight-normal;
    line-height: normal;
  }
  &:hover {
    border-bottom: $hoverBorder;
    p {
      font-weight: $font-weight-semi-bold;
    }
  }
  &:first-child {
    margin-left: $space-sm;
  }
  &:last-child {
    margin-right: $space-sm;
  }
}
