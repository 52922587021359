@import '../../scss/variables.scss';

.mailListContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px $mobilePadding 56px;
  h2 {
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    fill: $textColorLight;
    width: 100%;
    input {
      margin-top: 20px;
      height: 64px;
      width: 100%;
      max-width: 596px;
      filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
      flex-shrink: 0;
      padding: 21.5px 17px 18.5px;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    button {
      margin-top: 30px;
      width: 156px;
      height: 45px;
      flex-shrink: 0;
      background-color: $primaryColor;
      border: none;
      color: $textColorLight;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  @include smallBreakpoint {
    padding-left: $tabletPadding;
    padding-right: $tabletPadding;
  }
  @include largeBreakpoint {
    padding-left: $desktopPadding;
    padding-right: $desktopPadding;
  }
}
